import React, { useState } from "react";
import { useAuth } from "../../../src/services/auth";
import CurrencyUtil from "../../../src/utils/CurrencyUtil";
import { Icon, Input, Text, View } from "hubchain-storybook-design-pattern";
import { RequestStatusId } from "../../../src/enums/RequestStatusId.enum";
import { TCurrencySymbol } from "../../../src/types/currency";
import { TouchableOpacity } from "react-native";
import { RequestSwapModelExtended, SwapRateEditConfig } from "../index";

interface RateCellProps {
  request: RequestSwapModelExtended,
  rateEditConfig: SwapRateEditConfig | null,
  setRateEditConfig: React.Dispatch<React.SetStateAction<SwapRateEditConfig | null>>,
  isLoading: boolean,
  isFetching: boolean
}

const SwapRateCell = ({ request, rateEditConfig, setRateEditConfig, isFetching, isLoading }: RateCellProps) => {

  const { user } = useAuth();
  const isEditing = rateEditConfig?.request.id === request.id;
  const swap = request.swap;
  const [value, setValue] = useState(rateEditConfig?.newRateValue || "");

  const isValid = value && CurrencyUtil.getNumberByValue(value) > 0;

  return (
    <View style={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Text
          size={"13px"}
          style={{
            textAlign:
              user?.isHubchainUser &&
                request.swap.status.id === RequestStatusId.PENDING
                ? "right"
                : "left",
          }}
          variant={"gray"}
        >
          {CurrencyUtil.formatCurrency(
            swap.price.amount,
            swap.price.currency.currency,
            false,
            6,
            6,
          ) + " " + swap.price.currency.currency}


        </Text>

        {!!user?.isHubchainUser &&
          request.swap.status.id === RequestStatusId.PENDING && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 4,
              }}
            >
              {isEditing ? (
                <>
                  <Input
                    label={""}
                    size={"small"}
                    textAlign={"end"}
                    textVariant={"danger"}
                    autoFocus
                    coin={"CRYPTO"}
                    placeholder={"0,0000"}
                    decimalConfig={{
                      decimalScale: 4,
                      decimalsLimit: 4,
                    }}
                    paddingHorizontal={"4px"}
                    value={value}
                    onChange={(value) => setValue(value)}
                  />
                  <Text
                    size={"13px"}
                    style={{ textAlign: "right", marginLeft: 4 }}
                    variant={"danger"}
                  >
                    {swap.rate.currency.currency}
                  </Text>
                </>
              ) : (
                <>
                  <Text size={"13px"} variant={"danger"}>
                    Limit:
                  </Text>
                  <Text
                    size={"13px"}
                    style={{ textAlign: "right" }}
                    variant={"danger"}
                  >
                    {"- " + swap.rate.currency.currency}
                  </Text>
                </>
              )}
            </View>
          )}
      </View>

      {!!user?.isHubchainUser &&
        request.swap.status.id === RequestStatusId.PENDING && (
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginLeft: 8,
            }}
          >
            {isEditing && (
              <>
                <TouchableOpacity
                  onPress={() => setRateEditConfig(null)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 22,
                    height: 22,
                    borderRadius: 22,
                    backgroundColor: "#F1F1F1",
                    marginBottom: 4,
                  }}
                >
                  <Icon
                    name={"CloseIcon"}
                    fontSize={"18px"}
                    variant={"danger"}
                  />
                </TouchableOpacity>

                <TouchableOpacity
                  disabled={!isValid}
                  onPress={() =>
                    setRateEditConfig((state) => ({
                      ...state,
                      confirm: true,
                      newRateValue: value,
                    }))
                  }
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 22,
                    height: 22,
                    borderRadius: 22,
                    backgroundColor: "#F1F1F1",
                  }}
                >
                  <Icon
                    name={"CheckmarkIcon"}
                    fontSize={"18px"}
                    variant={isValid ? "success" : "gray"}
                  />
                </TouchableOpacity>
              </>
            )}

            {!isEditing && (
              <TouchableOpacity
                onPress={() =>
                  setRateEditConfig({
                    request,
                    newRateValue: "",
                    confirm: false,
                  })
                }
                disabled={isLoading || isFetching || request.isLoading}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 22,
                  height: 22,
                  borderRadius: 22,
                  backgroundColor: "#F1F1F1",
                }}
              >
                <Icon
                  name={"PencilFill"}
                  fontSize={"13px"}
                  variant={"dark"}
                />
              </TouchableOpacity>
            )}
          </View>
        )}
    </View>
  );
};

export default SwapRateCell;
