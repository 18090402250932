import {
	Button,
	Icon,
	Modal,
	Select,
	Text,
	View,
} from "hubchain-storybook-design-pattern";
import { TCurrencySymbol } from "../../types/currency";
import { Image, StyleSheet, ActivityIndicator } from "react-native";

import { setStringAsync } from "expo-clipboard";
import { useAlert } from "../../hooks/useAlert";
import { useTranslation } from "react-i18next";
import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";
import { QrCodePix } from "qrcode-pix";
import QRCode from "qrcode";
import { useEffect, useMemo, useRef, useState } from "react";
import RequestSwapService, {
	RequestSwapModel,
} from "../../services/swap/swapService";
import { isYesOrNoEnum } from "../../types/types";
import Util from "../../utils";
import { useCurrency } from "../../hooks/useCurrency";
import { BaasPixKeyTypeEnum } from "../TreasurySwapModal/CryptoHubTransferModal";
import React from "react";
import { UserModel } from "../../models/UserModel";
import { useQuery } from "react-query";
import Colors from "../../../src/global-constants/Colors";

interface PixQrCodeProps {
	type: "pix";
	base64: string;
	payload: string;
}

interface AddressQrCodeProps {
	type: "address";
	address: string;
}

interface QrCodeProps {
	type: "pix" | "address" | undefined;
	base64?: string;
	payload?: string;

	address?: string;
}

export interface CryptoHubDepositModalConfig {
	isDue?: isYesOrNoEnum;
	dueAt?: Date;
	isPaid?: isYesOrNoEnum;
	paidAt?: Date;

	amount: number;
	currency: TCurrencySymbol;
	network?: string;
	transactionId?: string;

	pix: {
		info: {
			type: string;
			name: string;
			taxId?: string;
			pix: {
				key: string;
				keyType?: BaasPixKeyTypeEnum;
				city: string;
				postalCode: string;
				message: string;
				transactionId: string;
			};
			personCompanyId: string;
			companyId?: string;
		};
		payload?: string;
		base64?: string;
	};
	request: RequestSwapModel;
}
export interface CryptoHubDepositModalProps {
	data?: CryptoHubDepositModalConfig;
	onClose: () => void;
	currency?: TCurrencySymbol | null;
	user?: UserModel | undefined;
}

const QRCodeFallback =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAXNSR0IArs4c6QAABJlJREFUeF7tndFu4zAMBJv//+gUd29WgAwWK7mWMH2lJZM7JCUrcfp6v9/vH/+OUeAl0GNY/g9EoGfxFOhhPAUq0NMUOCwe11CBHqbAYeFYoQI9TIHDwrFCBXqYAoeFY4UK9DAFDgvHChXoYQocFk5doa/X61ZJxo9vx/vTx7vkL82/Oljyn+4v0EEhgVqhVDSR3QodEooEseVCfqVrWJSu/75SEQIb5//r8RRv699HvO13imY79DQgT49PoOGXHAUaCra6JbVA2vGr47u9QmkTMjpEjw3ppqe9noDeHR8myOo19O6ACUC6RtN8d8cnUFgCUmDUQUhwGk8dheafflJEDpNDNJ4CJkBWaPkcurolpfPTmt0CX52wNP/2FSrQK2KBTl5jqYLaJYXmF6hAhxKHs9a0JaYZ3G6CaE2l+VfHRxV53MECCU6bHIEOCpCgqzOY7i/QsMZJUIFeBU2XlBBH/34oAU0doooiQaiFtvPTwUYa72z9lu9y0wBbwQVaptzsDBNo96M0Vmj42FXm/0fDml0Q04G2LZbG0xq62k7+tfY2YQQaVmgLjMYLNATSVjABae0CFeglh+qW22ZkO749uGjv/7TxAn0akdIfgZYCPm24QJ9GpPSnBto+GNP41WtkOn+qN+1aKf70fgJd/DqkQIevhKQVRALSWXFaEXQ9+WOFgoIkoEBBwNUVRBVAgAhwWyGPi3/2uy0EgASm8WRPAaXXUwKRf8vjF+j1V1xSwa3Qye+PUsUQICt0UDAVhDK6/TTkaePbhKMW/jH/7JabVsTo0NOAUMKSXaDwHLobcIEKNO2yl+vro7/bM3DyB9rU8kndtOXSfK1doIOCtAe4O4FTwAIV6PecWd2CaP7UbssNe0ArMN2Odr2zx9P9qGWTHuQv2ae33HSNoYMGCoAEnj2e7ifQ8gNmEligpEBopxZjhXYvJ00/+gv5Tr+cEoaWgNWbojRhqWWTgMvXUHKgtQv0qqBAFz+HWqFhyVqhkys0zcCQ18fltKsle7pmUsK08bdr5vRNURtQCpiAkV2goLhAu/8sZYWWn5daoWGFTs+48P+2UMcg/9LxtMamS0p7ff3YsjqgdP4USHvwkPrXAqPxAh0UShNCoJRiIPDslmmFhoJTxtMmhSqA5m8TgPwL87P+X250v+UtlwQnwQRKCBefFKUP9gJ92MdnbQUJVKBfexC1+Kd1EErorOH+rP8BZBKYAqIOMHuXSgJSQpA9nZ+u/4h/9ctKAs3OemlXToC33+VaoYftcjFjw3dhaAmgBKKW21Ygxntay20FF2hZASRguikSaNkDSPC7N0UCXQyUej7ZKWH+GiB1GIpvtn35Lrd1WKCZggId9KKGRUsIjc/w5FcLVKDDg2z59liag+lzXltR1PLJTvG145cf/VEArV2g3xWc3nJbYDReoAL9qgBtYqglkp0StB0/veWSw9rvVaBuufe6691IAYGSQpvZBboZMHJXoKTQZnaBbgaM3BUoKbSZXaCbASN3BUoKbWYX6GbAyF2BkkKb2QW6GTByV6Ck0GZ2gW4GjNwVKCm0mV2gmwEjd38BfQBXDkLpFO0AAAAASUVORK5CYII=";

export default function DepositModal({
	onClose,
	currency,
	user,
}: CryptoHubDepositModalProps) {
	const { showAlert } = useAlert();
	const { t } = useTranslation();
	const { currencies, markets } = useCurrency();
	const { tenant } = useMultiTenancy();

	const interval = useRef<any>();

	const [qrCode, setQrCode] = useState<QrCodeProps>();

	const {
		data: depositData,
		refetch: refetchAvailableDepositData,
		isFetching: isLoadingAvailableDepositData,
	} = useQuery(["AvailableNetworksAdress"], {
		queryFn: RequestSwapService.getDepositData,
		staleTime: 60 * 1000 * 5, // 5 minutes
		retry: 2,
		retryDelay: 60 * 1000 * 2,
	});

	const [network, setNetwork] = useState<any>();

	const availableNetworks = useMemo(() => {
		const baasData = depositData?.baasData;

		const networksData = depositData?.networksData;

		const parseNetwork = (network: any) => {
			return {
				value: network.network.nameId,
				icon: network.network.currencySymbol,
				label: network.network.description,
				address: network.address,
				description: network.network.description,
			};
		};

		if (baasData && currency === TCurrencySymbol.BRL) {
			const baas = {
				value: "pix-mainnet",
				icon: "BRL",
				label: "pix-mainnet",
				description: baasData?.taxId,

				city: baasData?.pix?.city,
				key: baasData?.pix?.key,
				name: baasData?.name,
				transactionId: baasData?.pix?.transitionId,
			};

			setNetwork(baas);

			return [baas];
		}

		if (networksData && currency) {
			const networkOptions = networksData?.[currency][0]?.networks;

			const availableNetworks = networkOptions
				? networkOptions.map(parseNetwork)
				: [];

			setNetwork(availableNetworks[0]);

			return availableNetworks;
		}

		return [];
	}, [depositData, currency]);

	const mountQrCodePix = async (baas: any) => {
		let qrCodePix: any;

		let qrCodePayload: any;

		let qrCode: any;

		if (baas) {
			qrCodePix = QrCodePix({
				version: "01",
				key: baas?.key, //or any PIX key
				name: baas?.name,
				city: baas?.city,
			});
		}

		if (qrCodePix) {
			qrCodePayload = qrCodePix.payload();

			qrCode = await qrCodePix.base64({ errorCorrectionLevel: "H" });
		}

		setQrCode({
			type: "pix",

			base64: qrCode,
			payload: qrCodePayload,

			address: undefined,
		});
	};

	const mountQrCode = async (address: string) => {
		const addressQrCode = await QRCode.toDataURL(address, {
			errorCorrectionLevel: "H",
		});

		setQrCode({
			type: "address",
			address: addressQrCode,
		});
	};

	useEffect(() => {
		if (currency === TCurrencySymbol.BRL) {
			mountQrCodePix(network);
		} else {
			if (network) {
				mountQrCode(network?.address);
			}
		}
	}, [user, currency, network]);

	const handleClose = () => {
		clearInterval(interval.current);

		onClose();
	};

	const handleCopyQrCode = () => {
		if (qrCode) {
			if (qrCode.type === "pix" && qrCode?.payload) {
				setStringAsync(qrCode.payload);
			} else if (qrCode.type === "address" && qrCode?.address) {
				setStringAsync(network.address);
			}

			showAlert("", "success", t(`cryptohub-card.deposit-modal.qrcode-copied`));
		}
	};

	const AwaitingPaymentView = () => {
		return (
			<>
				{qrCode?.address || qrCode?.base64 ? (
					<View>
						<View
							style={{
								justifyContent: "center",
								flexDirection: "row",
								alignItems: "center",
								display: "flex",
								gap: 4,
							}}
						>
							<Image
								source={{
									uri: qrCode?.base64 ? qrCode?.base64 : qrCode?.address,
								}}
								style={{
									height: 160,
									width: 160,
								}}
							/>

							<View
								style={{
									position: "absolute",
									borderRadius: "50%",
									overflow: "hidden",
									backgroundColor: "#FFF",
									padding: 1,
									border: "1px solid #F1F1F1",
								}}
							>
								<Image
									source={{
										uri:
											currency === TCurrencySymbol.BRL
												? tenant.favicon
												: `/static/images/currencies/${
														currency ?? "UNKNOWN"
												  }.svg`,
									}}
									style={{
										width: 42,
										height: 42,
									}}
								/>
							</View>
						</View>

						{currency !== TCurrencySymbol.BRL && (
							<View
								style={{
									borderColor: "#8484844D",
									borderBottomWidth: 1,
									paddingBottom: 12,
									marginBottom: 4,
								}}
							>
								<Text
									style={{
										textAlign: "center",
									}}
									fontStyle={"bold"}
									size={"12px"}
								>
									{network ? network?.address : "-"}
								</Text>
							</View>
						)}

						{currency === "BRL" && (
							<View
								style={{
									marginBottom: 4,
									paddingBottom: 12,
									borderBottomWidth: 1,
									borderColor: "#8484844D",
									gap: 8,
								}}
							>
								<Text
									style={{ textAlign: "center" }}
									fontStyle={"bold"}
									size={"14px"}
								>
									{network ? network?.name.toUpperCase() : ""}
								</Text>
								<Text
									style={{ textAlign: "center" }}
									fontStyle={"bold"}
									size={"12px"}
								>
									{t(`cryptohub-card.deposit-modal.person-company-id`)}{" "}
									{Util.maskCpfOrCnpj(network ? network?.description : "")}
								</Text>
							</View>
						)}
					</View>
				) : (
					<>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Image
								source={{ uri: QRCodeFallback }}
								style={{
									width: 160,
									height: 160,
								}}
								blurRadius={5}
							/>

							<View
								style={{
									position: "absolute",
									borderRadius: "50%",
									overflow: "hidden",
									backgroundColor: "#FFF",
									padding: 1,
									border: "1px solid #F1F1F1",
								}}
							>
								<View
									style={{
										width: 32,
										height: 32,
										display: "flex",
										alignItens: "center",
										justifyContent: "center",
									}}
								>
									<ActivityIndicator size={24} color={"#848484"} />
								</View>
							</View>

							<View
								style={{
									position: "absolute",
									filter: "blur(0.5)",
									width: "100%",
									height: "100%",
								}}
							/>
						</View>
					</>
				)}

				<Select
					size={"large"}
					label={t("swap-modal.destination.network")}
					labelStyle="default"
					inputWidth={"100%"}
					placeholder={""}
					iconSize={28}
					iconSet={"currency"}
					isSearchable={false}
					disabled={
						currency === TCurrencySymbol.BRL
							? true
							: availableNetworks?.length <= 1
							? true
							: false
					}
					readonly={
						currency === TCurrencySymbol.BRL
							? true
							: availableNetworks?.length <= 1
							? true
							: false
					}
					options={availableNetworks}
					value={network}
					onChange={(value) => setNetwork(value)}
					paddingRight={"10px"}
				/>

				<View style={{ paddingTop: 4, paddingBottom: 4 }}>
					<Button
						size={"medium"}
						label={t(
							currency === TCurrencySymbol.BRL
								? `cryptohub-card.deposit-modal.qrcode-copy`
								: `cryptohub-card.deposit-modal.qrcode-address-copy`
						)}
						icon={<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />}
						onClick={() => handleCopyQrCode()}
						disabled={qrCode?.address || qrCode?.base64 ? false : true}
					/>
					{false && currency !== TCurrencySymbol.BRL && (
						<Button
							size={"medium"}
							label={t(
								"cryptohub-card.deposit-modal.qrcode-metamask-mobile-link-copy"
							)}
							icon={<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />}
							onClick={() => handleCopyQrCode()}
							disabled={qrCode?.address || qrCode?.base64 ? false : true}
						/>
					)}
					{false && currency !== TCurrencySymbol.BRL && (
						<Button
							size={"medium"}
							label={t(
								"cryptohub-card.deposit-modal.qrcode-trustwallet-mobile-link-copy"
							)}
							icon={<Icon name={"CopyIcon"} fontSize={"14px"} color={"#FFF"} />}
							onClick={() => handleCopyQrCode()}
							disabled={qrCode?.address || qrCode?.base64 ? false : true}
						/>
					)}
				</View>

				{qrCode && (
					<View preset={"warning-box"}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 8,
								marginBottom: 8,
							}}
						>
							<Icon name={"WarningIcon"} variant={"warning"} />
							<Text fontStyle={"bold"}>
								{t(`cryptohub-card.deposit-modal.rules.title`)}
							</Text>
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: "column",
								lineHeight: 1.5,
								paddingLeft: 8,
								paddingRight: 8,
								textAlign: "left",
							}}
						>
							{currency !== TCurrencySymbol.BRL && (
								<Text>
									• {t(`cryptohub-card.deposit-modal.rules.rule-1-1-crypto`)}
								</Text>
							)}

							{currency === TCurrencySymbol.BRL && (
								<Text>
									•{" "}
									{t(`cryptohub-card.deposit-modal.rules.rule-1-1-fiat`, {
										tenant: tenant.companyName || tenant.name,
									})}
								</Text>
							)}

							{currency !== TCurrencySymbol.BRL && (
								<>
									<Text>
										• {t(`cryptohub-card.deposit-modal.rules.rule-1-2-crypto`)}{" "}
										<Text style={{ color: "red", fontWeight: "bold" }}>
											{network ? network?.description : "-"}
										</Text>
										.
									</Text>
								</>
							)}
						</View>
					</View>
				)}
			</>
		);
	};

	return (
		<Modal
			visible={true}
			title={t(`cryptohub-card.deposit-modal.title-1`)}
			titleIcon={"BoxArrowInDown"}
			onClose={handleClose}
			backgroundColor={"new"}
			footerDivider={true}
			customWidth={{
				width: "100%",
				maxWidth: "448px",
			}}
			fullHeightModal={true}
			contentOverflowY={"auto"}
			footer={
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						flex: 1,
					}}
				>
					<Button
						label={t(`cryptohub-card.deposit-modal.close`)}
						fillVariant={"ghost"}
						variant={"secondary"}
						size={"small"}
						onClick={handleClose}
					/>
				</View>
			}
		>
			<View style={[styles.card]}>
				<Text style={{ textAlign: "center" }} fontStyle={"bold"} size={"16px"}>
					{t(`cryptohub-card.deposit-modal.exchange`)}
				</Text>

				<AwaitingPaymentView />
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	card: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
		borderRadius: 8,
		padding: 16,
		gap: 8,
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
	},
});
