import { TCurrencySymbol } from "../../../src/types/currency";
import {
	RequestSwapModel,
	RequestSwapOnProcessSteps,
} from "../../../src/services/swap/swapService";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../src/services/auth";
import { RequestStatusId } from "../../../src/enums/RequestStatusId.enum";
import { isYesOrNoEnum } from "../../../src/types/types";
import { TableRow } from "../../../src/components/table/CustomTable";
import { Icon, MenuBox, Text, View } from "hubchain-storybook-design-pattern";
import GeneralCSS from "../../../src/global-constants/Styles";
import CurrencyIcon from "../../../src/components/CurrencyIcon";
import Util from "../../../src/utils";
import CurrencyUtil from "../../../src/utils/CurrencyUtil";
import { TouchableOpacity } from "react-native";
import { RequestTypeEnum } from "../../../src/enums/RequestType.enum";
import { CustomHoverableText } from "../../../src/components/LinkTouchableOpacity";
import { setStringAsync } from "expo-clipboard";
import {
	AvailableIconsType,
	IconVariants,
} from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { DataTable } from "react-native-paper";
import {
	RequestSwapModelExtended,
	SwapModalConfig,
	SwapRateEditConfig,
	WithdrawalModalConfig,
} from "../index";
import SwapRateCell from "./SwapRateCell";
import { CurrencyData } from "../../../src/services/currency/currencyService";
import { fi } from "date-fns/locale";

interface TableBodyProps {
	requests: RequestSwapModelExtended[];
	services: {
		currencies: CurrencyData[];
	};
	isLoading: boolean;
	isFetching: boolean;
	columnsMinWidth: number[];
	columnsMaxWidth: number[];
	setSwapModalConfig: (config: SwapModalConfig) => void;
	setWithdrawalModalConfig: (config: WithdrawalModalConfig | null) => void;
	handleOpenDepositModal: (config: {
		amount: number;
		currency: TCurrencySymbol;
		request: RequestSwapModel;
	}) => void;
	handleStopSwap: (request: RequestSwapModel) => void;
	handleResumeSwap: (request: RequestSwapModel) => void;
	handlePaymentSwap: (
		request: RequestSwapModel,
		data: { isPaid: RequestSwapModel["swap"]["isPaid"] }
	) => void;
	rateEditConfig: SwapRateEditConfig | null;
	setRateEditConfig: React.Dispatch<
		React.SetStateAction<SwapRateEditConfig | null>
	>;
}

const iconByRequestStatus: { [key: string]: AvailableIconsType } = {
	[RequestStatusId.COMPLETED]: "CheckmarkIcon",
	[RequestStatusId.PENDING]: "Exclamation",
	[RequestStatusId.RUNNING]: "Play",
	[RequestStatusId.CANCELLED]: "Slash",
	[RequestStatusId.EXPIRED]: "ClockHistory",
	[RequestStatusId.AWAITING_DEPOSIT]: "Exclamation",
	[RequestStatusId.AWAITING_WITHDRAWAL]: "Exclamation",
	[RequestStatusId.SCHEDULED]: "TimerIcon",
};

const iconVariantByRequestStatus: { [key: string]: IconVariants | string } = {
	[RequestStatusId.COMPLETED]: "success",
	[RequestStatusId.PENDING]: "warning",
	[RequestStatusId.RUNNING]: "warning",
	[RequestStatusId.CANCELLED]: "danger",
	[RequestStatusId.EXPIRED]: "danger",
	[RequestStatusId.AWAITING_DEPOSIT]: "danger",
	[RequestStatusId.AWAITING_WITHDRAWAL]: "danger",
	[RequestStatusId.SCHEDULED]: "primary",
};

const ExactlyInfoBadge = () => {
	const { t } = useTranslation();

	return (
		<View
			style={{
				position: "absolute",
				marginBottom: -30,
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				height: 12,
				backgroundColor: "#0052cc",
				width: "fit-content",
				padding: 2,
			}}
		>
			<Text size={"9px"} fontStyle={"bold"} variant={"white"}>
				{t(`swap.table.rows.exactly`)}
			</Text>
		</View>
	);
};

const SwapTableBody = ({
	requests,
	services,
	isLoading,
	isFetching,
	columnsMaxWidth,
	columnsMinWidth,
	setSwapModalConfig,
	setWithdrawalModalConfig,
	handleOpenDepositModal,
	handleStopSwap,
	handleResumeSwap,
	handlePaymentSwap,
	...rest
}: TableBodyProps) => {
	const { t } = useTranslation();
	const { user } = useAuth();

	const rows: ReactElement[] = [];

	requests.map((request: RequestSwapModelExtended, index: number) => {
		const swap = request.swap;

		const isPaused =
			request.swap?.status?.id === RequestStatusId.RUNNING &&
			request.swap?.isOnHold === isYesOrNoEnum.YES;

		// @ts-ignore
		rows.push(
			<TableRow
				key={request.id}
				index={index}
				zIndex={1}
				height={86}
				gap={8}
				opacity={request.isLoading ? 0.5 : 1}
				backgroundColor={
					request.isBlinking ? "rgba(255, 255, 0, 0.5)" : "rgba(255, 255, 0, 0)"
				}
			>
				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[0],
							maxWidth: columnsMaxWidth[0],
							justifyContent: "center",
							gap: 2,
						},
					]}
				>
					<Text size={"13px"}>{index + 1} </Text>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[1],
							maxWidth: columnsMaxWidth[1],
							justifyContent: "center",
						},
					]}
				>
					<View style={{ position: "absolute", width: "100%" }}>
						<Text
							data-tooltip-id={"tooltip-default"}
							data-tooltip-content={swap?.user?.name ?? "no user name"}
							size={"13px"}
							numberOfLines={1}
						>
							{swap?.user?.name ?? "no user name"}
						</Text>
						<a
							data-tooltip-id={"tooltip-default"}
							data-tooltip-content={swap?.user?.email ?? "no user email"}
						>
							<TouchableOpacity
								onPress={() =>
									window.open(
										`/users/accounts/${swap?.user?.id}`,
										"_blank",
										"noreferrer"
									)
								}
							>
								<CustomHoverableText variant={"gray"} size={"10px"}>
									{Util.maskBlockchainAddressOrTransactionId(
										swap?.user?.email ?? "no user email",
										8,
										14
									)}
								</CustomHoverableText>
							</TouchableOpacity>
						</a>
						<Text variant={"gray"} size={"9px"}>
							{request.id}
						</Text>
					</View>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[2],
							maxWidth: columnsMaxWidth[2],
							justifyContent: "center",
						},
					]}
				>
					<View
						style={[
							{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 4,
							} as any,
						]}
					>
						<CurrencyIcon
							name={swap.currencyIn.currency}
							style={{ width: 28, height: 28 }}
						/>

						<Text
							style={[
								{
									display: "flex",
									flexDirection: "column",
									lineHeight: "14px",
								},
							]}
						>
							<Text fontStyle={"bold"} size={"13px"}>
								{Util.getTranslatedProperty(
									swap.currencyIn,
									"name",
									t(`internalization.language`)
								)}
							</Text>
							<Text size={"13px"} variant={"gray"}>
								{swap.currencyIn.prefix || swap.currencyIn.currency}
							</Text>
						</Text>
					</View>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[3],
							maxWidth: columnsMaxWidth[3],
							justifyContent: "center",
						},
					]}
				>
					<View
						style={[
							{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								gap: 4,
							} as any,
						]}
					>
						<CurrencyIcon
							name={swap.currencyOut.currency}
							style={{ width: 28, height: 28 }}
						/>

						<Text
							style={[
								{
									display: "flex",
									flexDirection: "column",
									lineHeight: "14px",
								},
							]}
						>
							<Text fontStyle={"bold"} size={"13px"}>
								{Util.getTranslatedProperty(
									swap.currencyOut,
									"name",
									t(`internalization.language`)
								)}
							</Text>
							<Text size={"13px"} variant={"gray"}>
								{swap.currencyOut.prefix || swap.currencyOut.currency}
							</Text>
						</Text>
					</View>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[4],
							maxWidth: columnsMaxWidth[4],
							justifyContent: "center",
						},
					]}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: 6,
						}}
					>
						<Text size={"13px"}>
							{CurrencyUtil.formatCurrency(
								swap.amountIn,
								swap.currencyIn.currency
							)}
						</Text>

						{(swap.status.id === RequestStatusId.PENDING ||
							!!swap.transactionsIn.transactions.length) && (
							<a
								data-tooltip-id="tooltip-interactive"
								data-tooltip-html={`
									<div>
										<span style="font-weight: bold">Status: <span style="color: ${
											swap.isPaid === isYesOrNoEnum.YES ? "green" : "red"
										}">
										${
											swap.isPaid === isYesOrNoEnum.YES
												? "Pago"
												: swap.transactionsIn?.transactions[0]?.isDue ===
												  isYesOrNoEnum.YES
												? "Expirado"
												: "Não Pago"
										}</span></span>
										</br>
										${
											swap.status.id === RequestStatusId.PENDING ||
											swap.status.id === RequestStatusId.COMPLETED
												? `<span style="line-height: 1.5">clique para '${
														!!swap.transactionsIn?.transactions[0]
															? swap.isPaid === isYesOrNoEnum.YES
																? `Mostrar <b>${
																		swap.currencyIn.currency ===
																		TCurrencySymbol.BRL
																			? "Comprovante"
																			: "TxId na Blockchain"
																  }</b>`
																: `Mostrar <b>${
																		swap.currencyIn.currency ===
																		TCurrencySymbol.BRL
																			? "PIX"
																			: "Endereço da Carteira Blockchain"
																  }</b> para Pagamento`
															: `Gerar <b>${
																	swap.currencyIn.currency ===
																	TCurrencySymbol.BRL
																		? "PIX"
																		: "Endereço da Carteira Blockchain"
															  }</b> para Pagamento`
												  }'</span>
										`
												: `<span style="line-height: 1.5">clique para 'Ver mais informações'</span>`
										}
									</div>`}
							>
								<TouchableOpacity
									disabled={isLoading || isFetching || request.isLoading}
									onPress={() => {
										handleOpenDepositModal({
											amount: CurrencyUtil.getNumberByValue(swap.amountIn),
											currency: swap.currencyIn.currency,
											request: request,
										});
									}}
								>
									{swap.isPaid === isYesOrNoEnum.YES && (
										<Icon
											name={"Receipt"}
											fontSize={"14px"}
											variant={"success"}
										/>
									)}
									{swap.isPaid === isYesOrNoEnum.NO && (
										<Icon
											name={"QrCode"}
											fontSize={"14px"}
											variant={
												!!swap.transactionsIn.transactions[0] ||
												(swap.currencyIn.currency !== TCurrencySymbol.BRL &&
													swap.addressIn)
													? swap.transactionsIn?.transactions[0]?.isDue ===
															isYesOrNoEnum.YES ||
													  swap.status.id === RequestStatusId.CANCELLED
														? "danger"
														: "primary"
													: "gray"
											}
										/>
									)}
								</TouchableOpacity>
							</a>
						)}
					</View>

					{[
						RequestTypeEnum.swapExactFiatForCrypto,
						RequestTypeEnum.swapExactCryptoForFiat,
						RequestTypeEnum.swapExactCryptoForCrypto,
					].includes(swap.type.id) && <ExactlyInfoBadge />}

					{false && swap.transactionsIn?.transactions[0]?.transactionId && (
						<View
							style={{
								position: "absolute",
								marginBottom: 30,
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								height: 12,
								width: "fit-content",
								padding: 2,
							}}
						>
							<Text size={"10px"} fontStyle="bold" color={"#188351"}>
								<span style={{ color: "red" }}>
									{swap.transactionsIn?.transactions[0]?.transactionId}
								</span>
							</Text>
						</View>
					)}
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[5],
							maxWidth: columnsMaxWidth[5],
							justifyContent: "center",
						},
					]}
				>
					<Text size={"13px"}>
						{CurrencyUtil.formatCurrency(
							swap.netAmountOut,
							swap.currencyOut.currency,
							true,
							2
						)}
					</Text>

					{[
						RequestTypeEnum.swapFiatForExactCrypto,
						RequestTypeEnum.swapCryptoForExactFiat,
						RequestTypeEnum.swapCryptoForExactCrypto,
					].includes(swap.type.id) && <ExactlyInfoBadge />}
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[6],
							maxWidth: columnsMaxWidth[6],
							justifyContent: "center",
						},
					]}
				>
					<Text fontStyle={"bold"} size={"13px"}>
						{t("swap.table.rows.fees.service") + " "}
						<Text size={"13px"} variant={"gray"}>
							{CurrencyUtil.formatCurrency(
								swap.fees.service.amount,
								swap.fees.service.currency.currency
							) +
								" (" +
								CurrencyUtil.formatCurrency(
									Number(swap.fees.service.percentage) * 100,
									"PERCENTAGE",
									false,
									0,
									2
								) +
								"%)"}
						</Text>
					</Text>

					<Text fontStyle={"bold"} size={"13px"}>
						{t("swap.table.rows.fees.network") + " "}
						<Text size={"13px"} variant={"gray"}>
							{CurrencyUtil.formatCurrency(
								swap.fees.network.amount,
								swap.fees.network.currency.currency
							)}
						</Text>
					</Text>

					<View style={{ display: "flex", flexDirection: "row" }}>
						{!!swap.transactionsIn?.transactions[0]?.address ||
						!!swap.transactionsOut?.transactions[0]?.address ||
						(swap.currencyIn.currency !== TCurrencySymbol.BRL &&
							swap.addressIn) ? (
							<>
								<a
									data-tooltip-id="tooltip-interactive"
									data-tooltip-html={`<div><span>${
										swap.currencyIn.currency !== TCurrencySymbol.BRL
											? swap.addressIn
											: swap.addressOut
									}</span></br><span style="line-height: 1.5">${
										swap.currencyIn.currency !== TCurrencySymbol.BRL
											? swap.networkIn.description.split("(")[0]
											: swap.networkOut.description.split("(")[0]
									} (${
										swap.currencyIn.currency !== TCurrencySymbol.BRL
											? swap.networkIn.nameId
											: swap.networkOut.nameId
									})</span></div>`}
								>
									<TouchableOpacity
										onPress={() => {
											// console.log("::TouchableOpacity::onPress", (
											// 	swap.currencyIn.currency !== TCurrencySymbol.BRL
											// 		? swap.addressInExplorerUrl
											// 		: swap.transactionsOut?.transactions[0]?.addressExplorerUrl
											// ) ?? `/users/accounts/${swap.addressOut}`);

											return (
												(((!!swap.transactionsIn?.transactions[0]?.address ||
													!!swap.transactionsOut?.transactions[0]?.address ||
													(swap.currencyIn.currency !== TCurrencySymbol.BRL &&
														swap.addressInExplorerUrl)) &&
													(swap.transactionsOut?.transactions[0]
														?.addressExplorerUrl ||
														(swap.currencyIn.currency !== TCurrencySymbol.BRL &&
															swap.addressInExplorerUrl))) ||
													["internal-mainnet"].includes(
														swap.networkOut.nameId
													)) &&
												window.open(
													(swap.currencyIn.currency !== TCurrencySymbol.BRL
														? swap.addressInExplorerUrl
														: swap.transactionsOut?.transactions[0]
																?.addressExplorerUrl) ??
														`/users/accounts/${swap.addressOut}`,
													"_blank",
													"noreferrer"
												)
											);
										}}
									>
										<CustomHoverableText size={"11px"}>
											{Util.maskBlockchainAddressOrTransactionId(
												swap.currencyIn.currency !== TCurrencySymbol.BRL
													? swap.addressIn
													: swap.addressOut
											)}
										</CustomHoverableText>
									</TouchableOpacity>
								</a>
								<a
									data-tooltip-id={"tooltip-on-click"}
									data-tooltip-content={t(`general.tooltip.copied`)}
									data-tooltip-variant="success"
								>
									<TouchableOpacity
										style={{ marginLeft: 4 }}
										onPress={() => {
											setStringAsync(
												swap.currencyIn.currency !== TCurrencySymbol.BRL
													? swap.addressIn
													: swap.addressOut
											);
										}}
									>
										<Icon name={"CopyIcon"} fontSize={"11px"} />
									</TouchableOpacity>
								</a>
							</>
						) : (
							<>
								{["internal-mainnet"].includes(swap.networkOut?.nameId) ? (
									<>
										<a
											data-tooltip-id="tooltip-interactive"
											data-tooltip-html={`<div><span>${
												swap.currencyIn.currency === TCurrencySymbol.BRL
													? swap.addressIn
													: swap.addressOut
											}</span></br><span style="line-height: 1.5">${
												swap.currencyIn.currency === TCurrencySymbol.BRL
													? swap.networkIn.description.split("(")[0]
													: swap.networkOut.description.split("(")[0]
											} (${
												swap.currencyIn.currency === TCurrencySymbol.BRL
													? swap.networkIn.nameId
													: swap.networkOut.nameId
											})</span></div>`}
										>
											<TouchableOpacity
												onPress={() => {
													// console.log("::TouchableOpacity::onPress", (
													// 	swap.currencyIn.currency !== TCurrencySymbol.BRL
													// 		? swap.addressInExplorerUrl
													// 		: swap.transactionsOut?.transactions[0]?.addressExplorerUrl
													// ) ?? `/users/accounts/${swap.addressOut}`);

													return (
														// (
														// 	// (
														// 	// 	!!swap.transactionsIn?.transactions[0]?.address
														// 	// 	||
														// 	// 	!!swap.transactionsOut?.transactions[0]?.address
														// 	// 	||
														// 	// 	(
														// 	// 		swap.currencyOut.currency === TCurrencySymbol.BRL
														// 	// 		&& swap.addressInExplorerUrl
														// 	// 	)
														// 	// )
														// 	// &&
														// 	// (
														// 	// 	swap.transactionsOut?.transactions[0].addressExplorerUrl
														// 	// )
														// )
														// &&
														window.open(
															swap.currencyIn.currency === TCurrencySymbol.BRL
																? swap.addressInExplorerUrl
																: swap.transactionsOut?.transactions?.[0]
																		?.addressExplorerUrl ??
																		`/users/accounts/${swap.addressOut}`,
															"_blank",
															"noreferrer"
														)
													);
												}}
											>
												<CustomHoverableText size={"11px"}>
													{Util.maskBlockchainAddressOrTransactionId(
														swap.currencyIn.currency === TCurrencySymbol.BRL
															? swap.addressIn
															: swap.addressOut
													)}
												</CustomHoverableText>
											</TouchableOpacity>
										</a>
										<a
											data-tooltip-id={"tooltip-on-click"}
											data-tooltip-content={t(`general.tooltip.copied`)}
											data-tooltip-variant="success"
										>
											<TouchableOpacity
												style={{ marginLeft: 4 }}
												onPress={() => {
													setStringAsync(
														swap.currencyIn.currency === TCurrencySymbol.BRL
															? swap.addressIn
															: swap.addressOut
													);
												}}
											>
												<Icon name={"CopyIcon"} fontSize={"11px"} />
											</TouchableOpacity>
										</a>
									</>
								) : (
									<Text size={"11px"} style={{ color: "#ef6400" }}>
										{t(`swap.table.rows.wallet.empty`)}
									</Text>
								)}
							</>
						)}
					</View>

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							height: 14,
							backgroundColor: "gray",
							width: "fit-content",
							padding: 2,
							gap: 2,
						}}
					>
						<CurrencyIcon
							// name={swap.networkIn?.currencySymbol}
							// currency={swap.networkIn.currency}
							name={
								services?.currencies
									?.filter((x) => x.currency === swap.currencyIn.currency)[0]
									?.withdraw.networks.filter(
										(x) => x.nameId === swap.networkIn.nameId
									)[0]?.currencySymbol ?? swap.currencyIn.currency
							}
							style={{ width: 14, height: 14 }}
						/>

						<Text size={"8px"} fontStyle={"bold"} variant={"white"}>
							{swap.networkIn?.description?.split("(")[0].trim() || ""}
						</Text>
					</View>

					<View
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							height: 14,
							backgroundColor: "gray",
							width: "fit-content",
							padding: 2,
							gap: 2,
						}}
					>
						<CurrencyIcon
							// name={swap.networkOut?.currencySymbol}
							// currency={swap.currencyOut.currency}
							name={
								services?.currencies
									?.filter((x) => x.currency === swap.currencyOut.currency)[0]
									?.withdraw.networks.filter(
										(x) => x.nameId === swap.networkOut.nameId
									)[0]?.currencySymbol ?? swap.currencyOut.currency
							}
							style={{ width: 14, height: 14 }}
						/>

						<Text size={"8px"} fontStyle={"bold"} variant={"white"}>
							{swap.networkOut?.description?.split("(")[0].trim() || ""}
							{!["internal-mainnet"].includes(swap.networkOut?.nameId) &&
							swap.currencyOut.currency === TCurrencySymbol.BRL
								? " - " +
								  (Util.maskBlockchainAddressOrTransactionId(swap.addressOut) ??
										"destinatário não informado")
								: ""}
						</Text>
					</View>

					{!!swap.transactionsIn?.transactions[0]?.transactionId &&
						!swap.currencyIn.currency.includes(TCurrencySymbol.BRL) && (
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
								}}
							>
								<TouchableOpacity
									onPress={() =>
										window.open(
											swap.transactionsIn?.transactions[0]
												.transactionIdExplorerUrl,
											"_blank",
											"noreferrer"
										)
									}
								>
									<View
										style={{ display: "flex", flexDirection: "row", gap: 2 }}
									>
										<Text size={"11px"} fontStyle={"bold"} variant={"dark"}>
											tx:
										</Text>
										<CustomHoverableText size={"11px"} variant={"dark"}>
											{Util.maskBlockchainAddressOrTransactionId(
												swap.transactionsIn?.transactions[0]?.transactionId
											)}
										</CustomHoverableText>
									</View>
								</TouchableOpacity>

								<a
									data-tooltip-id={"tooltip-on-click"}
									data-tooltip-content={t(`general.tooltip.copied`)}
								>
									<TouchableOpacity
										style={{ marginLeft: 4 }}
										onPress={() =>
											setStringAsync(
												swap.transactionsIn?.transactions[0]?.transactionId!
											)
										}
									>
										<Icon name={"CopyIcon"} fontSize={"11px"} />
									</TouchableOpacity>
								</a>
							</View>
						)}

					{!!swap.transactionsOut?.transactions[0]?.transactionId && (
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<TouchableOpacity
								onPress={() =>
									window.open(
										swap.transactionsOut?.transactions[0]
											.transactionIdExplorerUrl,
										"_blank",
										"noreferrer"
									)
								}
							>
								<View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
									<Text size={"11px"} fontStyle={"bold"} variant={"dark"}>
										tx:
									</Text>
									<CustomHoverableText size={"11px"} variant={"dark"}>
										{Util.maskBlockchainAddressOrTransactionId(
											swap.transactionsOut?.transactions[0]?.transactionId
										)}
									</CustomHoverableText>
								</View>
							</TouchableOpacity>

							<a
								data-tooltip-id={"tooltip-on-click"}
								data-tooltip-content={t(`general.tooltip.copied`)}
							>
								<TouchableOpacity
									style={{ marginLeft: 4 }}
									onPress={() =>
										setStringAsync(
											swap.transactionsOut?.transactions[0]?.transactionId!
										)
									}
								>
									<Icon name={"CopyIcon"} fontSize={"11px"} />
								</TouchableOpacity>
							</a>
						</View>
					)}
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[7],
							maxWidth: columnsMaxWidth[7],
							justifyContent: "center",
						},
					]}
				>
					<SwapRateCell
						request={request}
						isFetching={isFetching}
						isLoading={isLoading}
						rateEditConfig={rest.rateEditConfig}
						setRateEditConfig={rest.setRateEditConfig}
					/>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[8],
							maxWidth: columnsMaxWidth[8],
							justifyContent: "center",
							gap: 0,
						},
					]}
				>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
						}}
					>
						{
							<Icon
								name={
									isPaused
										? "Pause"
										: iconByRequestStatus[request.swap.status.id]
								}
								fontSize={14}
								variant={
									isPaused
										? "warning"
										: (iconVariantByRequestStatus[
												request.swap.status.id
										  ] as any)
								}
								background={{ type: "circle" }}
							/>
						}
						<Text style={{ marginLeft: 8 }} size={"13px"}>
							{isPaused
								? t(`swap.table.rows.status.paused`)
								: Util.getTranslatedProperty(
										request.swap.status,
										"name",
										t(`internalization.language`)
								  )}
						</Text>
					</View>
					{swap.status.id === RequestStatusId.RUNNING &&
						[
							RequestSwapOnProcessSteps.CommissionInGateway_ON,
							RequestSwapOnProcessSteps.CommissionInGateway_OK,
							RequestSwapOnProcessSteps.CommissionInReferral0_ON,
							RequestSwapOnProcessSteps.CommissionInReferral0_OK,
							RequestSwapOnProcessSteps.CommissionInReferral1_ON,
							RequestSwapOnProcessSteps.CommissionInReferral1_OK,
							RequestSwapOnProcessSteps.NetAmountIn_ON,
							RequestSwapOnProcessSteps.NetAmountIn_OK,
							//
							RequestSwapOnProcessSteps.CommissionOutGateway_ON,
							RequestSwapOnProcessSteps.CommissionOutGateway_OK,
							RequestSwapOnProcessSteps.CommissionOutReferral0_ON,
							RequestSwapOnProcessSteps.CommissionOutReferral0_OK,
							RequestSwapOnProcessSteps.CommissionOutReferral1_ON,
							RequestSwapOnProcessSteps.CommissionOutReferral1_OK,
							RequestSwapOnProcessSteps.NetAmountOut_ON,
							RequestSwapOnProcessSteps.NetAmountOut_OK,
							//
							RequestSwapOnProcessSteps.Trade_ON,
							RequestSwapOnProcessSteps.Trade_OK,
							//
							RequestSwapOnProcessSteps.Withdrawal_PENDING,
							RequestSwapOnProcessSteps.Withdrawal_ON,
							RequestSwapOnProcessSteps.Withdrawal_CONFIRMING,
							RequestSwapOnProcessSteps.Withdrawal_OK,
							//
							// RequestSwapOnProcessSteps.Done,
						].includes(swap.step as RequestSwapOnProcessSteps) && (
							<Text
								variant={"dark"}
								size={"10px"}
								style={{
									marginLeft: 24,
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									minHeight: 16,
									gap: 2,
								}}
							>
								<Icon
									name={
										swap.step?.toString().endsWith("_OK")
											? "CheckmarkIcon"
											: "WarningIcon"
									}
									fontSize={"10px"}
									variant={
										swap.step?.toString().endsWith("_OK")
											? "success"
											: "warning"
									}
								/>
								{t(`swap.table.rows.status.step.${swap.step}`)}
							</Text>
						)}
					<Text variant={"gray"} size={"10px"} style={{ marginLeft: 24 }}>
						{Util.formatDate(
							swap.updatedAt || swap.createdAt,
							undefined,
							"L HH[h]mm"
						)}
					</Text>
				</View>

				<View
					style={[
						GeneralCSS.defaultTableValue,
						{
							minWidth: columnsMinWidth[9],
							maxWidth: columnsMaxWidth[9],
							alignItems: "flex-end",
							justifyContent: "center",
						},
					]}
				>
					{!(isLoading || isFetching || request.isLoading) && (
						<MenuBox
							options={
								[
									RequestStatusId.RUNNING,
									RequestStatusId.PENDING,
									RequestStatusId.AWAITING_WITHDRAWAL,
									RequestStatusId.SCHEDULED,
								].includes(request.swap.status.id)
									? [
											{
												icon: "FileEarmarkText",
												title: t(`swap.table.menu.view`),
												onPress: () => {
													setSwapModalConfig({
														visible: true,
														request: request,
														currencyIn: undefined,
														action: "view",
													});
												},
											},
											{
												icon: (request.swap.status.id ===
													RequestStatusId.RUNNING && !isPaused
													? "Pause"
													: "PlayCircle") as AvailableIconsType,
												onPress: () =>
													request.swap.status.id === RequestStatusId.RUNNING &&
													!isPaused
														? handleStopSwap(request)
														: handleResumeSwap(request),
												title: t(
													`swap.table.menu.${
														request.swap.status.id ===
															RequestStatusId.RUNNING && !isPaused
															? "stop"
															: [
																	RequestStatusId.PENDING,
																	RequestStatusId.SCHEDULED,
															  ].includes(request.swap.status.id)
															? "run"
															: "resume"
													}`
												),
												visible: [
													RequestStatusId.RUNNING,
													RequestStatusId.PENDING,
													RequestStatusId.SCHEDULED,
												].includes(request.swap.status.id),
											},

											{
												icon: "CreditCardSolid" as AvailableIconsType,
												onPress: () =>
													handlePaymentSwap(request, {
														isPaid: isYesOrNoEnum.YES,
													}),
												title: t(`swap.table.menu.is-paid`),
												visible:
													!!user?.isHubchainUser &&
													request.swap.isPaid === isYesOrNoEnum.NO &&
													[
														RequestStatusId.RUNNING,
														RequestStatusId.PENDING,
														RequestStatusId.SCHEDULED,
													].includes(request.swap.status.id),
											},
											{
												icon: "CheckmarkCircleIcon" as AvailableIconsType,
												onPress: () => setWithdrawalModalConfig({ request }),
												title: t(`swap.table.menu.withdraw-done`),
												visible:
													!!user?.isHubchainUser &&
													([RequestStatusId.AWAITING_WITHDRAWAL].includes(
														request.swap.status.id
													) ||
														(request.swap.step &&
															[
																RequestSwapOnProcessSteps.Withdrawal_CONFIRMING,
															].includes(request.swap.step))),
											},
											{
												icon: "Edit" as AvailableIconsType,
												onPress: () =>
													setSwapModalConfig({
														visible: true,
														request: request,
														currencyIn: null,
														action: "edit",
													}),
												title: t(`swap.table.menu.edit`),
												visible: false,
											},
											{
												icon: "CloseCircleIcon" as AvailableIconsType,
												onPress: () =>
													setSwapModalConfig({
														visible: true,
														request: request,
														isCancel: true,
														currencyIn: null,
														action: "cancel",
													}),
												title: t(`swap.table.menu.cancel`),
												visible:
													[
														RequestStatusId.RUNNING,
														RequestStatusId.PENDING,
														RequestStatusId.SCHEDULED,
													].includes(request.swap.status.id) || isPaused,
											},
									  ]
									: [
											{
												icon: "FileEarmarkText",
												title: t(`swap.table.menu.view`),
												onPress: () => {
													setSwapModalConfig({
														visible: true,
														request: request,
														currencyIn: undefined,
														action: "view",
													});
												},
											},
									  ]
							}
						/>
					)}
				</View>
			</TableRow>
		);
	});

	if (!rows?.length) {
		rows.push(
			<TableRow key={0} style={[GeneralCSS.defaultTableRow]}>
				<DataTable.Cell>
					<Text style={{ marginTop: 12, marginLeft: 6 }} size={"13px"}>
						{t(`swap.table.${isFetching ? "loading" : "no"}-rows`)}
					</Text>
				</DataTable.Cell>
			</TableRow>
		);
	}

	return <>{rows}</>;
};

export default SwapTableBody;
