import { SelectSearchOption } from "hubchain-storybook-design-pattern/lib/components/SelectSearch/SelectSearch";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import SwapChangeTypeButton from "../../UserSwapModal/components/SwapChangeTypeButton";
import RequestTransferService from "../../../services/transfer/transferService";
import DetailsCardTransferModal from "../components/DetailsCardTransferModal";
import TransferHistoryCard from "../components/TransferHistoryCard";
import UsersService from "../../../services/approvedUsers/UsersService";
import RequestSwapService from "../../../services/swap/swapService";
import { CurrencyTypeEnum } from "../../../models/CurrencyModel";
import TitleTransferModal from "../components/TitleTransferModal";
import { TCurrencySymbol } from "../../../types/currency";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { useCurrency } from "../../../hooks/useCurrency";
import { useMutation, useQuery } from "react-query";
import { UserModel } from "../../../models/UserModel";
import { ActivityIndicator, TouchableOpacity } from "react-native";
import { validate as uuidValidate } from "uuid";
import { useTranslation } from "react-i18next";
import Util from "../../../utils";
import { useFormik } from "formik";
import DepositModal from "../../../components/DepositModal";
import styles from "../styles";
import * as Yup from "yup";

import {
	SelectSearch,
	Button,
	Select,
	Input,
	Modal,
	View,
	Icon,
	Text,
} from "hubchain-storybook-design-pattern";

import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	useRef,
} from "react";
import {
	validateCNPJ,
	validateCPF,
	validateEmail,
	validatePhone,
} from "validations-br";
import DetailRowTransferModal from "../components/DetailRowTransferModal";
import { useAlert } from "../../../hooks/useAlert";
import { INetwork } from "src/services/currency/currencyService";
import {
	AvailableIconsType,
	IconVariantsType,
} from "hubchain-storybook-design-pattern/lib/components/Icon/types";

import WalletService from "../../../services/wallet/walletService";

// import { useAuth } from "src/services/auth";

interface IUserOption extends SelectSearchOption {
	user: UserModel;
}
export interface CryptohubTransferModalProps {
	onClose: () => void;
	user?: UserModel;
	currency?: TCurrencySymbol | null;
}

interface ICurrencyOption extends SelectOptionModel {
	value: TCurrencySymbol;
	type: CurrencyTypeEnum;
}

interface INetworkOption extends SelectOptionModel {
	value: string;
	walletConfig: {
		placeholder: string;
		maxLength: number;
		validateName: string;
	};
}

export interface TransferFormProps {
	cryptohubFrom: any;
	userFrom?: IUserOption | null;

	cryptohubTo: any;
	userTo?: IUserOption | null;

	externalPix?: string | null;

	externalAddressOut?: string | null;
	externalNetworkOut?: INetworkOption | null;

	currency: ICurrencyOption;
	amount: string;
}

// ///////////////////////////////////////////////////////

export interface ICryptoHubBankAccountAddressTransfer {
	type: "bank";
	name: string; // "HUBCHAIN TECNOLOGIA LTDA"
	taxId: string; // "28643027000111",
	bank: {
		type: "CHECK" | "SAVING";
		code: string; // "341"
		branch: string; // "7069"
		account: string; // "48013-0"
	};
}

export interface ICryptoHubPixAddressTransfer {
	type: "pix";
	name: string; // "HUBCHAIN TECNOLOGIA LTDA"
	taxId: string; // "28643027000111",
	pix: {
		keyType: BaasPixKeyTypeEnum;
		key: string;
		transactionId?: string;
		description?: string;
	};
}

export interface ICryptoHubP2pAddressTransfer {
	type: "p2p";
	name?: string; // "HUBCHAIN TECNOLOGIA LTDA"
	taxId?: string; // "28643027000111",
	p2p: {
		account: string;
	};
}

export interface ICryptoHubInternalAddressTransfer {
	type: "internal";
	internal: {
		id?: string; // 96117695-9d9c-46b3-a0de-53a0fcb0af0d
		email?: string;
	};
}

export type ICryptoHubAddressTransfer =
	| ICryptoHubBankAccountAddressTransfer
	| ICryptoHubPixAddressTransfer
	| ICryptoHubP2pAddressTransfer
	| ICryptoHubInternalAddressTransfer;

export interface ICryptoHubEWalletAccountTransfer {
	serviceName: "cryptohub";
	accountUniqueId?:
		| "ezzepay"
		| "bankei"
		| "onilx"
		| "banprime"
		| "hubchain"
		| "hubchain2";
	network: "internal-mainnet";
	address: ICryptoHubInternalAddressTransfer;
}

export interface ICryptoHubBaasAccountTransfer {
	serviceName: "ezzebank" | "cora" | "inter" | "pinbank";
	accountUniqueId:
		| "ezzepay"
		| "bankei"
		| "onilx"
		| "banprime"
		| "hubchain"
		| "hubchain2";
	network: "pix-mainnet";
	address:
		| ICryptoHubBankAccountAddressTransfer
		| ICryptoHubPixAddressTransfer
		| ICryptoHubP2pAddressTransfer;
}

export interface ICryptoHubExchangeAccountTransfer {
	serviceName: "exchange";
	accountUniqueId:
		| "ezzepay"
		| "bankei"
		| "onilx"
		| "banprime"
		| "hubchain"
		| "hubchain2";
	network: "pix-mainnet" | "p2p-mainnet";
	address?: ICryptoHubAddressTransfer;
}

export type ICryptoHubAccountTransfer =
	| ICryptoHubEWalletAccountTransfer
	| ICryptoHubBaasAccountTransfer
	| ICryptoHubExchangeAccountTransfer;

export interface ICryptoHubTransferCreateServiceRequest {
	from: ICryptoHubAccountTransfer;
	to: ICryptoHubAccountTransfer;
	amount: string;
	currency: TCurrencySymbol;
}

interface DepositModalConfig {
	visible: boolean;
	currency: TCurrencySymbol | null;
}

type TransferStatusType = "awaiting" | "creating" | "success" | "error";

// ///////////////////////////////////////////////////////

export enum BaasPixKeyTypeEnum {
	TELEFONE = "TELEFONE",
	CPF = "CPF",
	CNPJ = "CNPJ",
	EMAIL = "EMAIL",
	CHAVE_ALEATORIA = "CHAVE_ALEATORIA",
}

interface ExternalPixKeyTypeProps {
	value: BaasPixKeyTypeEnum;
	label: string;
}

const MaxValueButton = ({
	onPress,
	disabled,
}: {
	onPress: () => void;
	disabled: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<TouchableOpacity onPress={onPress} disabled={disabled}>
			<Text variant={"primary"} fontStyle={"bold"} size={"12px"}>
				{t("swap-modal.origin.max-value")}
			</Text>
		</TouchableOpacity>
	);
};

const parseUserToOption = (user: UserModel): IUserOption => {
	return {
		value: user.id,
		label: user.name || user.personLegalName,
		subLabel: user.email,
		user: user,
		query: `${user.name} ${user.personLegalName} ${user.personCompanyId} ${user.email}`,
	};
};

function ConfirmationModal({
	onClose,
	handleConfirm,

	//new
	data,
	transferStatus,
}) {
	const modalRef = useRef(null);

	const { t } = useTranslation();

	const cryptohubFromData = data.cryptohubFrom;
	const userFromData = data.userFrom;
	const cryptohubToData = data.cryptohubTo;
	const userToData = data.userTo;
	const amount = data.amount;
	const currency = data.currency.value;
	const externalPix = data.externalPix;
	const externalPixKeyType = data.externalPixKeyType;
	const externalAddress = data.externalAddressOut;
	const externalNetwork = data.externalNetworkOut;

	return (
		<Modal
			ref={modalRef}
			title={t("transfer-modal.confirmation-modal.title")}
			titleIcon={"SwapVertIcon"}
			visible={true}
			onClose={onClose}
			contentOverflowY={"auto"}
			fullHeightModal={true}
			onClickOutside={() => {}}
			backgroundColor={"new"}
			customWidth={{
				width: "100%",
				maxWidth: "352px",
				minWidth: "352px",
			}}
			isLoading={false}
			footer={
				<View style={[styles.footer]}>
					<View style={[styles.footerButtons]}>
						{transferStatus === "error" && (
							<>
								<Button
									variant={"secondary"}
									size={"small"}
									fillVariant={"ghost"}
									label={t("transfer-modal.close")}
									onClick={() => onClose()}
								/>
								<Button
									icon={<Icon name={"Send2"} fontSize={"20px"} />}
									size={"small"}
									label={t("transfer-modal.confirmation-modal.submit")}
									onClick={() => handleConfirm()}
								/>
							</>
						)}
						{transferStatus === "success" && (
							<>
								<Button
									variant={"secondary"}
									size={"small"}
									fillVariant={"ghost"}
									label={t("transfer-modal.close")}
									onClick={() => onClose()}
								/>
							</>
						)}

						{transferStatus === "creating" && (
							<>
								<Button
									variant={"secondary"}
									size={"small"}
									fillVariant={"ghost"}
									icon={<ActivityIndicator size={16} />}
									label={""}
									onClick={() => onClose()}
									disabled
								/>
							</>
						)}

						{transferStatus === "awaiting" && (
							<>
								<Button
									variant={"secondary"}
									size={"small"}
									fillVariant={"ghost"}
									label={t("transfer-modal.cancel")}
									onClick={() => onClose()}
								/>
								<Button
									icon={<Icon name={"Send2"} fontSize={"20px"} />}
									size={"small"}
									label={t("transfer-modal.confirmation-modal.submit")}
									onClick={() => handleConfirm()}
								/>
							</>
						)}
					</View>
				</View>
			}
			footerDivider={true}
		>
			<View style={[styles.card, { height: "auto" }]}>
				<View
					style={{
						display: "flex",
						flexDirection: "column",
						gap: 10,
					}}
				>
					{/* From */}

					{cryptohubFromData?.accountType === "baas" && (
						<>
							<DetailRowTransferModal
								label={"Origem"}
								value={cryptohubFromData?.label}
							/>
						</>
					)}
					{cryptohubFromData?.accountType === "exchange" && (
						<>
							<DetailRowTransferModal
								label={"Origem"}
								value={cryptohubFromData?.label}
							/>
						</>
					)}
					{cryptohubFromData?.accountType === "e-wallet" && (
						<>
							<DetailRowTransferModal
								label={"Origem"}
								value={
									userFromData?.user?.personLegalName
										? userFromData?.user?.personLegalName
										: "-"
								}
							/>
						</>
					)}

					{/* To */}

					{cryptohubToData?.accountType === "baas" && (
						<>
							<DetailRowTransferModal
								label={"Destino"}
								value={cryptohubToData?.label}
							/>
						</>
					)}

					{cryptohubToData?.accountType === "exchange" && (
						<>
							<DetailRowTransferModal
								label={"Destino"}
								value={cryptohubToData?.label}
							/>
						</>
					)}

					{cryptohubToData?.accountType === "e-wallet" && (
						<>
							<DetailRowTransferModal
								label={"Destino"}
								value={
									userToData?.user?.personLegalName
										? userToData?.user?.personLegalName
										: "-"
								}
							/>
						</>
					)}

					{cryptohubToData?.accountType === "external-pix" && (
						<>
							<DetailRowTransferModal label={"Destino"} value={externalPix} />
						</>
					)}

					{cryptohubToData?.accountType === "external-address-out" && (
						<>
							<DetailRowTransferModal
								label={"Destino"}
								value={`${externalAddress}`}
							/>
						</>
					)}

					<View
						style={{
							borderBottomWidth: 1,
							borderColor: "#8484844D",
						}}
					/>

					<Text variant={"primary"} size={"16px"} fontStyle={"bold"}>
						{`Valor: ${CurrencyUtil.formatCurrency(
							Util.truncate(amount.toString().replaceAll(",", "."), 8),
							TCurrencySymbol?.[currency]
						)}`}
					</Text>

					<View
						style={{
							borderBottomWidth: 1,
							borderColor: "#8484844D",
						}}
					/>

					<DetailRowTransferModal
						label={t("transfer-modal.confirmation-modal.status.title")}
						icon={{
							name:
								transferStatus === "error"
									? "ErrorIcon"
									: transferStatus === "awaiting"
									? "WaitIcon"
									: transferStatus === "creating"
									? "AutoRenewIcon"
									: transferStatus === "success"
									? "CheckCircle"
									: "Question",
							variant:
								transferStatus === "error"
									? "danger"
									: transferStatus === "awaiting"
									? "warning"
									: transferStatus === "creating"
									? "gray"
									: transferStatus === "success"
									? "success"
									: "primary",
							fontSize: "20",
						}}
						value={t(
							`transfer-modal.confirmation-modal.status.${transferStatus}`
						)}
					/>
				</View>
			</View>
		</Modal>
	);
}

export const CryptoHubTransferModal = ({
	onClose,
	user,
	currency,
}: CryptohubTransferModalProps) => {
	const { t } = useTranslation();

	const modalRef = useRef(null);

	const { showAlert } = useAlert();

	const [depositModalConfig, setDepositModalConfig] =
		useState<DepositModalConfig>({
			visible: false,
			currency: null,
		});

	const {
		data: _cryptohubInfoBalancesData,
		refetch: refetchCryptoHubInfoBalances,
		isFetching,
	} = useQuery(["CryptoHubInfoBalances"], {
		queryFn: RequestSwapService.getCryptoHubInfoBalances,
		retryDelay: 60 * 1000 * 2,
		staleTime: 60 * 1000 * 5, // 5 minutes
		retry: 2,
	});

	const {
		data: cryptohubOptions,
		refetch: refetchAvailableCryptoHubInfoBalances,
		isFetching: isLoadingAvailableCryptoHubInfoBalances,
	} = useQuery(["AvailableCryptoHubInfoBalances"], {
		queryFn: RequestSwapService.getAvailableCryptoHubInfoBalances,
		staleTime: 60 * 1000 * 5, // 5 minutes
		retry: 2,
		retryDelay: 60 * 1000 * 2,
	});

	const [balanceVisible, setBalanceVisible] = useState<boolean>(true);
	const [currentCurrency, setCurrentCurrency] = useState(
		currency ? currency : "BRL"
	);
	const [externalPix, setExternalPix] = useState<boolean>(false);

	const [userFrom, setUserFrom] = useState(null);

	const [userTo, setUserTo] = useState(null);

	const [externalPixKeyType, setExternalPixKeyType] =
		useState<ExternalPixKeyTypeProps>({
			value: BaasPixKeyTypeEnum.CPF,
			label: "CPF",
		});

	const [confirmationModalVisible, setConfirmationModalVisible] =
		useState<boolean>(false);

	const [transferStatus, setTransferStatus] =
		useState<TransferStatusType>("awaiting");

	function handleOpenConfirmationModal() {
		setConfirmationModalVisible(true);
		refetchAvailableCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	}

	function handleCloseConfirmationModal() {
		transferForm.setFieldValue("amount", "");
		setTransferStatus("awaiting");
		setConfirmationModalVisible(false);
		refetchAvailableCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	}

	const pixKeyTypes: ExternalPixKeyTypeProps[] = [
		{
			value: BaasPixKeyTypeEnum.EMAIL,
			label: "E-mail",
		},
		{
			value: BaasPixKeyTypeEnum.TELEFONE,
			label: "Celular",
		},
		{
			value: BaasPixKeyTypeEnum.CPF,
			label: "CPF",
		},
		{
			value: BaasPixKeyTypeEnum.CNPJ,
			label: "CNPJ",
		},
		{
			value: BaasPixKeyTypeEnum.CHAVE_ALEATORIA,
			label: "Chave Aleatória",
		},
	];

	const transferFormValidationSchema = Yup.object({
		cryptohubFrom: Yup.object().required(),
		userFrom: Yup.object().when("cryptohubFrom.accountType", {
			is: "e-wallet",
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		cryptohubTo: Yup.object().required(),
		userTo: Yup.object().when("cryptohubTo.accountType", {
			is: "e-wallet",
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		externalPix: Yup.string().when("cryptohubTo.accountType", {
			is: "external-pix",
			then: (schema) =>
				schema
					.test("Valid Key", "Invalid Key", (key) => {
						switch (externalPixKeyType?.value) {
							case "CPF":
								return validateCPF(key);

							case "CNPJ":
								return validateCNPJ(key);

							case "EMAIL":
								return validateEmail(key);

							case "TELEFONE":
								return validatePhone(key);

							case "CHAVE_ALEATORIA":
								return uuidValidate(key);
						}
					})
					.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		externalPixKeyType: Yup.object().when("cryptohubTo.accountType", {
			is: "external-pix",
			then: (schema) => schema.required(),
			otherwise: (schema) => schema.notRequired(),
		}),
		amount: Yup.string()
			.required()
			.test("valid-amount", "invalid-amount", (amount) => {
				const _amount = CurrencyUtil.getNumberByValue(amount);

				const amountMin =
					CurrencyUtil.getNumberByValue(
						transferForm?.values?.currency?.withdraw?.networks.find(
							(network) =>
								network.nameId ===
								transferForm?.values?.externalNetworkOut?.value
						)?.amountMin
					) +
					CurrencyUtil.getNumberByValue(
						transferForm?.values?.currency?.withdraw?.networks.find(
							(network) =>
								network.nameId ===
								transferForm?.values?.externalNetworkOut?.value
						)?.amountFee
					);

				if (_amount <= 0 || Number.isNaN(_amount)) return false;

				if (
					transferForm?.values?.cryptohubFrom?.balances?.[
						transferForm.values.currency.value
					]?.available < amountMin
				) {
					return false;
				}

				if (
					_amount <=
						transferForm?.values?.cryptohubFrom?.balances?.[
							transferForm.values.currency.value
						]?.available &&
					_amount >= amountMin
				) {
					return true;
				}

				return false;
			}),
		currency: Yup.object().required(),
		externalAddressOut: Yup.string().when("cryptohubTo.accountType", {
			is: "external-address-out",
			then: (schema) =>
				schema
					.required()
					.test(
						"valid-address",
						"invalid-address",
						async (address, context) => {
							const transferFormValues = context.parent;

							if (!address || address?.length < 20) {
								return false;
							}

							try {
								const parsedNetwork =
									transferFormValues?.externalNetworkOut?.walletConfig
										?.validateName;

								const { isValid } = WalletService.addressValidate(
									address,
									parsedNetwork
								);

								return isValid;
							} catch (err) {
								return false;
							}
						}
					),
			otherwise: (schema) => schema.notRequired(),
		}),
	});

	const parsedAccounts = useMemo(() => {
		const parseAccountToOption = (account: any) => {
			return {
				accountType: account.accountType,
				serviceName: account.serviceName,
				accountUniqueId: account.accountUniqueId,
				network: account.network,
				address: account.address,
				balances: account.balances ?? null,
				icons: {
					label:
						account.accountType === "e-wallet"
							? "PeopleFill"
							: account.accountType === "exchange"
							? "Globe2"
							: "Bank",
				},
				value:
					account.accountType === "e-wallet"
						? account.accountType
						: `${account.accountType} ${account.accountUniqueId}`,
				label: `${account.accountDescription} (${account.accountUniqueId})`,
				details: account.cashIn,
			} as any; // SelectSearchOption
		};

		return cryptohubOptions ? cryptohubOptions.map(parseAccountToOption) : [];
	}, [cryptohubOptions]);

	const cryptohubAccountsAvailable = useMemo(() => {
		return parsedAccounts.map((account) => ({
			...account,
			subLabel:
				account.balances && currentCurrency && account.balances[currentCurrency]
					? CurrencyUtil.formatCurrency(
							account?.balances[currentCurrency]?.available,
							TCurrencySymbol?.[currentCurrency]
					  )
					: "",
		}));
	}, [parsedAccounts, currentCurrency]);

	const hasInitialized = useRef(false);

	useEffect(() => {
		if (!hasInitialized.current && cryptohubAccountsAvailable.length > 0) {
			if (user) {
				const cryptohubAccount = cryptohubAccountsAvailable.find(
					(account) => account.value === "e-wallet"
				);

				const parsedUser = parseUserToOption(user);

				transferForm.setFieldValue("cryptohubFrom", {
					...cryptohubAccount,
					...{
						balances: user.userBalances.reduce(
							(items: Record<string, any>, item: any) => {
								items[item.currency.currency] = item;
								return items;
							},
							{} as Record<string, any>
						),
					},
				});

				transferForm.setFieldValue("userFrom", parsedUser);
				setUserFrom(parsedUser.value);

				const cryptohubTo = cryptohubAccountsAvailable.find(
					(account) => account?.accountType === "exchange"
				);

				transferForm.setFieldValue("cryptohubTo", cryptohubTo);
			} else {
				transferForm.setFieldValue(
					"cryptohubFrom",
					cryptohubAccountsAvailable[0]
				);

				transferForm.setFieldValue(
					"cryptohubTo",
					cryptohubAccountsAvailable[1]
				);
			}
			hasInitialized.current = true;
		}
	}, [cryptohubAccountsAvailable, user]);

	const defaultBalance = [
		{
			currency: TCurrencySymbol.BRL,
			exchange: 0,
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.BTC,
			exchange: 0,
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.USDT,
			exchange: 0,
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.USDC,
			exchange: 0,
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
		{
			currency: TCurrencySymbol.ETH,
			exchange: 0,
			baas: 0,
			swaps: 0,
			diff: 0,
			userId: "",
		},
	];

	// Submit
	const {
		mutate: cryptohubTransferCreate,
		isLoading: isCryptoHubTransferCreating,
		data: cryptohubTransferServiceResponse,
	} = useMutation({
		mutationFn: RequestTransferService.create,
		onMutate: () => {
			setTransferStatus("creating");
		},
		onSuccess: () => {
			if (cryptohubTransferServiceResponse?.data?.status === "error") {
				showAlert(
					cryptohubTransferServiceResponse?.data?.message ??
						"CryptoHub: Internal Error",
					"danger",
					t("transfer-modal.error")
				);
				setTransferStatus("error");
				refetchAvailableCryptoHubInfoBalances();
				refetchCryptoHubInfoBalances();
			} else {
				showAlert(t("transfer-modal.success"), "success");
				refetchAvailableCryptoHubInfoBalances();
				refetchCryptoHubInfoBalances();
				setTransferStatus("success");
			}
		},
		onError: () => {
			showAlert(t("transfer-modal.error"), "danger");
			setTransferStatus("error");
			refetchAvailableCryptoHubInfoBalances();
			refetchCryptoHubInfoBalances();
		},
	});

	const handleTransferCreateSubmit = async (values: TransferFormProps) => {
		const cryptohubFrom = values.cryptohubFrom;
		const cryptohubTo = values.cryptohubTo;
		const currency = values.currency.value;
		const externalPix = values.externalPix;
		const externalAddressOut = values.externalAddressOut;
		const externalNetworkOut = values.externalNetworkOut;

		const amount = Util.truncate(
			values.amount.toString().replaceAll(",", "."),
			8
		);

		const addressFrom =
			cryptohubFrom.accountType === "e-wallet"
				? {
						type: "internal",
						internal: {
							id: values?.userFrom?.user?.id,
						},
				  }
				: cryptohubFrom.address;

		const addressTo =
			cryptohubTo.accountType === "e-wallet"
				? {
						type: "internal",
						internal: {
							id: values?.userTo?.user?.id,
						},
				  }
				: externalPix
				? externalPix
				: cryptohubTo.accountType === "external-address-out"
				? externalAddressOut
				: cryptohubTo.address;

		const networkTo =
			cryptohubTo.accountType === "exchange"
				? cryptohubFrom.network
				: cryptohubTo.accountType === "external-address-out"
				? externalNetworkOut
				: cryptohubTo.network;

		const _transferRequestCreate: ICryptoHubTransferCreateServiceRequest = {
			from: {
				serviceName:
					cryptohubFrom.accountType === "e-wallet"
						? "cryptohub"
						: cryptohubFrom.serviceName,
				accountUniqueId: cryptohubFrom.accountUniqueId,
				address: addressFrom,
				network:
					cryptohubFrom.accountType === "exchange"
						? cryptohubTo.network
						: cryptohubFrom.network,
			},
			to: {
				serviceName:
					cryptohubTo.accountType === "e-wallet"
						? "cryptohub"
						: cryptohubTo.serviceName,
				accountUniqueId: cryptohubTo.accountUniqueId,
				address: addressTo,
				network: networkTo,
			},
			amount,
			currency,
		};

		// cryptohubTransferCreate(_transferRequestCreate);
		cryptohubTransferCreate(_transferRequestCreate);
		refetchAvailableCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	};

	const handleConfirmTransfer = () => {
		handleTransferCreateSubmit(transferForm.values);
		refetchAvailableCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	};

	// Currencies

	const { currencies, markets } = useCurrency();

	const { currencyOptions, currencyFiatOptions, networkOptions } =
		useMemo(() => {
			const availableCurrencies: TCurrencySymbol[] = currencies.map(
				(currency) => currency.currency
			);

			const currencyFiatOptions = markets
				.map((market) => market.quoteCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index && availableCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.BRL ? -1 : 1
				)
				.map((currency) => ({
					value: currency?.currency,
					label: currency?.currency,
					subLabel: currency?.name,
					icon: currency?.currency,
					type: currency?.type,
					networks: currency?.withdraw?.networks.map(
						(network) => network.nameId
					),
					withdraw: currency?.withdraw,
				}));

			const currencyCryptoOptions = markets
				.map((market) => market.baseCurrency)
				.map((currency) => ({
					value: currency?.currency,
					label: currency?.currency,
					subLabel: currency?.name,
					icon: currency?.currency,
					type: currency?.type,
					networks: currency?.withdraw?.networks.map(
						(network) => network.nameId
					),
					withdraw: currency?.withdraw,
				}));

			const parsedCurrencyOptions = [
				...currencyFiatOptions,
				...currencyCryptoOptions,
			];

			const currencyOptions = currency
				? parsedCurrencyOptions.filter((a) => a.value === currency)
				: parsedCurrencyOptions;

			// Simulation

			let networks: INetwork[] = [];

			currencies.forEach((currency) => {
				currency.withdraw.networks.forEach((network) => networks.push(network));
			});

			const networkOptions: INetworkOption[] = networks
				.filter(
					(network, index, array) =>
						array.findIndex((item) => item.nameId === network.nameId) === index
				) //exclude duplicate networks
				.map((network) => ({
					value: network.nameId,
					label:
						network.currencySymbol === TCurrencySymbol.BRL
							? network.currencySymbol
							: network.description,
					icon: network.currencySymbol,
					...(network.currencySymbol === TCurrencySymbol.BRL
						? {
								subLabel:
									network.nameId === "internal-mainnet"
										? "e-wallet"
										: network.nameId,
						  }
						: {}),
					walletConfig: {
						placeholder: network.placeholder,
						validateName: network.nameId.split("-")[0],
						maxLength: 42,
					},
				}));

			return {
				currencyOptions,
				currencyFiatOptions,
				networkOptions,
			};
		}, [currencies, markets]);

	// Form Data

	const transferForm = useFormik<TransferFormProps>({
		initialValues: useMemo(() => {
			return {
				cryptohubFrom: cryptohubAccountsAvailable[0],
				userFrom: null,

				cryptohubTo: cryptohubAccountsAvailable[1],
				userTo: null,

				externalPix: null,
				externalPixKeyType: null,

				externalAddressOut: null,
				externalNetworkOut: null,

				amount: "",
				currency: currencyOptions[0],
			} as TransferFormProps;
		}, []),
		validationSchema: transferFormValidationSchema,
		onSubmit: handleTransferCreateSubmit,
	});

	// Handle Invert Press

	const handleInvertAccounts = useCallback(async () => {
		const cryptohubFrom = transferForm.values.cryptohubTo;
		const cryptohubTo = transferForm.values.cryptohubFrom;
		const userFromTransferForm = transferForm.values.userFrom;
		const userToTransferForm = transferForm.values.userTo;

		transferForm.setFieldValue("cryptohubFrom", cryptohubFrom);
		transferForm.setFieldValue("userFrom", userToTransferForm);
		transferForm.setFieldValue("cryptohubTo", cryptohubTo);
		transferForm.setFieldValue("userTo", userFromTransferForm);
		setUserFrom(userTo);
		setUserTo(userFrom);
	}, [transferForm.values.cryptohubFrom, transferForm.values.cryptohubTo]);

	// Fix

	useEffect(() => {
		setTimeout(() => {
			setBalanceVisible(true);
		}, 0); //Rerender component to fix select modal

		refetchAvailableCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	}, []);

	useEffect(() => {
		if (
			!!isCryptoHubTransferCreating &&
			!isLoadingAvailableCryptoHubInfoBalances
		) {
			refetchAvailableCryptoHubInfoBalances();
			refetchCryptoHubInfoBalances();
		}
	}, [isCryptoHubTransferCreating]);

	// Balance

	// users

	const userService = UsersService.getInstance();

	const [filter, setFilter] = useState({
		page: 0,
		limit: 20,
		total: 0,
		search: {
			name: { like: "" },
			// id: { equal: userId },
			isLocked: { equal: "" },
		},
	});

	const {
		data: getUsersResponse,
		refetch: refetchUsers,
		isFetching: isLoadingUsers,
	} = useQuery(["users"], {
		queryFn: () => userService.getUsers(filter), //
		// queryFn: () => userService.getAllUsers(),
		staleTime: 60 * 5 * 1000, //5 minutes
	});

	// const disableSubmitButton = !swapForm.values.netAmountOut

	const disableSubmitButton = !transferForm.isValid;
	// const disableSubmitButton = !swapForm.isValid;

	const users: UserModel[] | undefined = getUsersResponse?.data?.rows;

	const [defaultUsers, setDefaultUsers] = useState<IUserOption[] | []>([]);

	const usersAccountOptions: IUserOption[] = useMemo(() => {
		if (defaultUsers.length === 0) {
			setDefaultUsers(users ? users.map(parseUserToOption) : []);
		}
		return users ? users.map(parseUserToOption) : [];
	}, [getUsersResponse, users]);

	const usersAccountFromOptions = userTo
		? usersAccountOptions.filter((user) => user?.value !== userTo)
		: usersAccountOptions;

	const usersAccountToOptions = userFrom
		? usersAccountOptions.filter((user) => user?.value !== userFrom)
		: usersAccountOptions;

	const onChangeSearch = async (text: string) => {
		if (text) {
			filter.search = Util.mountDefaultUserSearch(text, filter.search);

			if (text?.length >= 3 || !text?.length) {
				const response = await refetchUsers();

				const users = response?.data?.data.rows;

				return users ? users.map(parseUserToOption) : [];
			} else {
				return defaultUsers;
			}
		}
	};

	const externalAccount = {
		accountType: "external-address-out",
		accountUniqueId: "external-address-out",
		address: null,
		balances: null,
		icons: {
			label: "SwapVertIcon",
		},
		label: "Endereço de Carteira Externa",
		network: "external-address-out",
		serviceName: "external-address-out",
		value: "external-address-out",
	};

	// useMemo para cryptohubAccountsFrom e cryptohubAccountsTo
	const { cryptohubAccountsFrom, cryptohubAccountsTo } = useMemo(() => {
		let cryptohubAccountsTo;

		let cryptohubAccountsFrom;

		if (user && currency) {
			if (currency === "BRL") {
				cryptohubAccountsTo = cryptohubAccountsAvailable
					? cryptohubAccountsAvailable.filter(
							(account) =>
								account?.value !== transferForm?.values?.cryptohubFrom?.value ||
								account?.value === "e-wallet"
					  )
					: [];

				cryptohubAccountsFrom = cryptohubAccountsAvailable
					? cryptohubAccountsAvailable.filter(
							(account) =>
								account?.value !== transferForm?.values?.cryptohubTo?.value ||
								account?.value === "e-wallet"
					  )
					: [];
			} else {
				cryptohubAccountsTo = cryptohubAccountsAvailable
					? [
							...cryptohubAccountsAvailable.filter(
								(a) => a.accountType !== "baas"
							),
					  ]
					: [];

				cryptohubAccountsFrom = cryptohubAccountsAvailable
					? cryptohubAccountsAvailable.filter((a) => a.accountType !== "baas")
					: [];
			}
		} else {
			cryptohubAccountsFrom = cryptohubAccountsAvailable
				? cryptohubAccountsAvailable.filter(
						(account) =>
							account?.value !== transferForm?.values?.cryptohubTo?.value ||
							account?.value === "e-wallet"
				  )
				: [];

			cryptohubAccountsTo = cryptohubAccountsAvailable
				? [
						...cryptohubAccountsAvailable.filter(
							(account) =>
								account?.value !== transferForm?.values?.cryptohubFrom?.value ||
								account?.value === "e-wallet"
						),
						// externalAccount,
				  ]
				: [];
		}

		if (
			transferForm.values.cryptohubFrom?.accountType === "baas" &&
			cryptohubAccountsFrom &&
			cryptohubAccountsTo &&
			!user //added
		) {
			const newSelectedOptionFrom = cryptohubAccountsFrom.find(
				(account) => account.value === transferForm.values.cryptohubFrom.value
			);

			const newSelectedOptionTo = cryptohubAccountsTo.find(
				(account) => account.value === transferForm.values.cryptohubTo.value
			);

			if (newSelectedOptionFrom) {
				transferForm.setFieldValue("cryptohubFrom", newSelectedOptionFrom);
				transferForm.setFieldValue("cryptohubTo", newSelectedOptionTo);
			}
		}

		if (
			transferForm.values.cryptohubFrom?.accountType !== "baas"
			// &&
			// transferForm.values.currency.value !== "BRL"
		) {
			cryptohubAccountsTo = [...cryptohubAccountsTo, externalAccount];
		} else {
		}

		return { cryptohubAccountsFrom, cryptohubAccountsTo };
	}, [
		cryptohubAccountsAvailable,
		transferForm.values.cryptohubTo,
		transferForm.values.cryptohubFrom,
		transferForm.values.userFrom,
		transferForm.values.currency,
		user,
	]);

	const userFromBalance =
		transferForm?.values?.userFrom?.user.userBalances.filter(
			(balance) =>
				balance.currency.currency === transferForm.values.currency.value
		)?.[0]?.available;

	const handleRefresh = () => {
		refetchCryptoHubInfoBalances();
		refetchCryptoHubInfoBalances();
	};

	const openDepositModal = (currency: TCurrencySymbol) => {
		setDepositModalConfig({
			visible: true,
			currency: currency,
		});
	};

	const closeDepositModal = () => {
		setDepositModalConfig({
			visible: false,
			currency: null,
		});
	};

	const getWalletValidationIconProps = (): {
		name: AvailableIconsType;
		variant: IconVariantsType;
	} => {
		const isWalletValid =
			transferForm.errors.externalAddressOut === undefined &&
			transferForm.values.externalAddressOut !== "";

		return {
			name: isWalletValid ? "CheckCircleFill" : "DashCircleFill",
			variant: isWalletValid ? "success" : "danger",
		};
	};

	return (
		<>
			<Modal
				ref={modalRef}
				title={t("transfer-modal.title")}
				titleIcon={"SwapVertIcon"}
				visible={true}
				onClose={onClose}
				contentOverflowY={"scroll"}
				fullHeightModal={true}
				onClickOutside={() => {}}
				backgroundColor={"new"}
				customWidth={{
					width: "100%",
					maxWidth: "896px",
					minWidth: "180px",
				}}
				isLoading={false}
				footer={
					<View style={[styles.footer]}>
						<View style={[styles.footerButtons]}>
							<Button
								variant={"secondary"}
								size={"small"}
								fillVariant={"ghost"}
								label={t("transfer-modal.close")}
								onClick={() => onClose()}
							/>
							<Button
								icon={<Icon name={"Send2"} fontSize={"20px"} />}
								label={t("transfer-modal.submit")}
								size={"small"}
								onClick={() => handleOpenConfirmationModal()}
								disabled={
									Object.keys(transferForm.errors).length === 0 ? false : true
								}
								disableHover={
									Object.keys(transferForm.errors).length === 0 ? false : true
								}
							/>
						</View>
					</View>
				}
				footerDivider={true}
			>
				<View
					style={[
						styles.container,
						{
							minHeight: 432,
							height: "fit-content",
						},
					]}
				>
					<View style={[styles.card, { minWidth: 360, height: "auto" }]}>
						<TitleTransferModal
							title={t("transfer-modal.cryptohub-from")}
							icon={"BoxArrowInDown"}
							rightAddon={
								<View
									style={{
										display: "flex",
										flexDirection: "row",
										gap: "4px",
									}}
								>
									<Button
										icon={
											balanceVisible ? (
												<Icon name={"EyeSlashFill"} fontSize={"16px"} />
											) : (
												<Icon name={"EyeFill"} fontSize={"16px"} />
											)
										}
										label={""}
										size={"small"}
										onClick={() => {
											setBalanceVisible(!balanceVisible);
										}}
										fillVariant="ghost"
									/>
									<Button
										icon={
											isFetching ? (
												<ActivityIndicator size={16} />
											) : (
												<Icon name={"Redo"} fontSize={"16px"} />
											)
										}
										label={""}
										size={"small"}
										onClick={() => handleRefresh()}
										fillVariant="ghost"
									/>
								</View>
							}
						/>

						<SelectSearch
							options={cryptohubAccountsFrom}
							icons={{
								label: "Bank",
							}}
							selectedOption={transferForm.values.cryptohubFrom}
							// disabled={isLoadingAvailableCryptoHubInfoBalances}
							// readonly={isLoadingAvailableCryptoHubInfoBalances}
							disabled={
								transferForm?.values?.cryptohubFrom?.accountType ===
									"e-wallet" &&
								user &&
								user?.id === transferForm.values.userFrom?.user?.id
									? true
									: false
							}
							readonly={
								transferForm?.values?.cryptohubFrom?.accountType ===
									"e-wallet" &&
								user &&
								user?.id === transferForm.values.userFrom?.user?.id
									? true
									: false
							}
							width={"100%"}
							// readonly={user ? true : false}
							// disabled={user ? true : false}
							noOptionsMessage={t("transfer-modal.loading")}
							onChange={(option) => {
								refetchCryptoHubInfoBalances();

								if (option?.accountType !== "e-wallet") {
									transferForm.setFieldValue("userFrom", null);
								}

								if (option?.accountType === "baas") {
									setCurrentCurrency("BRL");

									transferForm.setFieldValue("externalAddressOut", null);

									transferForm.setFieldValue(
										"currency",
										currencyOptions.find((currency) => currency.type === "fiat")
									);
								}

								if (
									option?.accountType === "baas" &&
									transferForm?.values?.cryptohubTo?.accountType ===
										"external-address-out"
								) {
									if (!user) {
										setExternalPix(false);
										transferForm.setFieldValue(
											"cryptohubTo",
											cryptohubAccountsAvailable
												? cryptohubAccountsAvailable.filter(
														(account) => account?.value !== option?.value
												  )[0]
												: []
										);
									}
								}

								if (option?.accountType !== "external-address-out") {
									transferForm.setFieldValue("externalNetworkOut", null);
								}

								if (option?.accountType !== "baas") {
									if (!user) {
										setExternalPix(false);
										transferForm.setFieldValue(
											"cryptohubTo",
											cryptohubAccountsAvailable
												? cryptohubAccountsAvailable.filter(
														(account) => account?.value !== option?.value
												  )[0]
												: []
										);
									}
								}

								transferForm.setFieldValue("externalPix", null);
								transferForm.setFieldValue("cryptohubFrom", option);
							}}
							hideSubLabelContent={!balanceVisible}
							hiddenSubLabelContent={
								transferForm.values.currency.type === "fiat"
									? `R$ ***,**`
									: `***,**`
							}
						/>

						{transferForm?.values?.cryptohubFrom?.accountType ===
							"e-wallet" && (
							// USER FROM
							<>
								<View
									style={{
										justifyContent: "space-between",
										flexDirection: "row",
										display: "flex",
									}}
								>
									<View>
										<Text variant={"primary"} size={"13px"} fontStyle={"bold"}>
											{t("transfer-modal.user-account")}
										</Text>
									</View>
								</View>
								<SelectSearch
									options={usersAccountFromOptions}
									selectedOption={transferForm.values.userFrom ?? null}
									icons={{
										label: "PersonFill",
										subLabel: "EnvelopeFill",
									}}
									disabled={
										transferForm?.values?.cryptohubFrom?.accountType ===
											"e-wallet" &&
										user &&
										user?.id === transferForm.values.userFrom?.user?.id
											? true
											: false
									}
									readonly={
										transferForm?.values?.cryptohubFrom?.accountType ===
											"e-wallet" &&
										user &&
										user?.id === transferForm.values.userFrom?.user?.id
											? true
											: false
									}
									width={"100%"}
									noOptionsMessage={
										isLoadingUsers && usersAccountOptions?.length === 0
											? t("transfer-modal.loading-accounts")
											: undefined
									}
									onChange={(option) => {
										refetchCryptoHubInfoBalances();
										setUserFrom(option.value);
										transferForm.setFieldValue("cryptohubFrom", {
											...transferForm.values.cryptohubFrom,
											...{
												balances: option.user.userBalances.reduce(
													(items: any[], item: any) => {
														items[item.currency.currency] = item;

														return items;
													},
													{}
												),
											},
										});

										if (option.value === transferForm?.values?.userTo?.value) {
											transferForm.setFieldValue("userTo", null);
										}

										transferForm.setFieldValue("userFrom", option);
									}}
									loadOptions={onChangeSearch}
									defaultOptions={usersAccountFromOptions}
								/>
							</>
						)}

						<TitleTransferModal
							title={t("transfer-modal.cryptohub-to")}
							icon={"ArrowDownIcon"}
							badgeText={t("swap.table.rows.exactly")}
							showBadge={false}
							rightAddon={
								<>
									{/* {!user && ( */}
									{transferForm?.values?.cryptohubTo?.accountType !==
										"external-address-out" && (
										<SwapChangeTypeButton
											onPress={handleInvertAccounts}
											disabled={
												isLoadingUsers ||
												isLoadingAvailableCryptoHubInfoBalances
											}
										/>
									)}
									{/* )} */}
								</>
							}
						/>

						<>
							<SelectSearch
								options={cryptohubAccountsTo}
								icons={{
									label: "Bank",
								}}
								selectedOption={transferForm.values.cryptohubTo}
								width={"100%"}
								noOptionsMessage={t("transfer-modal.loading")}
								onChange={(option) => {
									refetchCryptoHubInfoBalances();
									if (option?.accountType === "external-pix") {
										setExternalPix(true);
										transferForm.setFieldValue(
											"currency",
											currencyOptions.find(
												(currency) => currency.type === "fiat"
											)
										);
										transferForm.setFieldValue(
											"externalPixKeyType",
											externalPixKeyType
										);
									}

									if (option?.accountType === "baas") {
										setCurrentCurrency("BRL");

										setExternalPix(false);
										transferForm.setFieldValue(
											"currency",
											currencyOptions.find(
												(currency) => currency.type === "fiat"
											)
										);
									}

									if (option?.accountType !== "e-wallet") {
										setExternalPix(false);
										transferForm.setFieldValue("userTo", null);
									}

									if (option?.accountType === "external-address-out") {
										// 	transferForm?.values?.currency?.value === "BRL"
										// 		? networkOptions?.filter((network) =>
										// 				currencyOptions
										// 					.filter((currency) => currency.type !== "fiat")[0]
										// 					?.networks?.includes(network.value)
										// 		  )
										// 		: networkOptions?.filter(
										// 				(network) =>
										// 					transferForm?.values?.currency?.networks?.includes(
										// 						network.value
										// 					)[0]
										// 		  )
										// );

										setExternalPix(false);

										setCurrentCurrency(
											currencyOptions.filter(
												(currency) => currency.type !== "fiat"
											)[0]?.value
										);

										transferForm.setFieldValue(
											"currency",
											currencyOptions.filter(
												(currency) => currency.type !== "fiat"
											)[0]
										);

										if (transferForm?.values?.currency?.value === "BRL") {
											transferForm.setFieldValue(
												"externalNetworkOut",
												networkOptions?.filter((network) =>
													currencyOptions
														.filter((currency) => currency.type !== "fiat")[0]
														?.networks?.includes(network.value)
												)[0]
											);
										} else {
											transferForm.setFieldValue(
												"externalNetworkOut",
												networkOptions?.filter(
													(network) =>
														transferForm?.values?.currency?.networks?.includes(
															network.value
														)[0]
												)
											);
										}
									}

									transferForm.setFieldValue("cryptohubTo", option);
								}}
								disabled={isLoadingAvailableCryptoHubInfoBalances}
								readonly={isLoadingAvailableCryptoHubInfoBalances}
								// disabled={
								// 	transferForm?.values?.cryptohubTo?.accountType === "e-wallet"
								// 	&& user?.id === transferForm?.values?.userTo?.user?.id
								// }
								// readonly=
								// {
								// 	transferForm?.values?.cryptohubTo?.accountType === "e-wallet" &&
								// 	user?.id === transferForm?.values?.userTo?.user?.id
								// }
								hideSubLabelContent={!balanceVisible}
								hiddenSubLabelContent={
									transferForm.values.currency.type === "fiat"
										? `R$ ***,**`
										: `***,**`
								}
							/>

							{transferForm?.values?.cryptohubTo?.accountType ===
								"e-wallet" && (
								// USER TO
								<>
									<View
										style={{
											justifyContent: "space-between",
											flexDirection: "row",
											display: "flex",
										}}
									>
										<View>
											<Text
												variant={"primary"}
												size={"13px"}
												fontStyle={"bold"}
											>
												{t("transfer-modal.user-account")}
											</Text>
										</View>
									</View>

									<SelectSearch
										// options={usersAccountOptions}
										options={usersAccountToOptions}
										selectedOption={
											transferForm.values.userTo
												? transferForm.values.userTo
												: null
										}
										icons={{
											label: "PersonFill",
											subLabel: "EnvelopeFill",
										}}
										// readonly={isLoadingAvailableCryptoHubInfoBalances}
										// disabled={isLoadingAvailableCryptoHubInfoBalances}
										disabled={
											transferForm?.values?.cryptohubTo?.accountType ===
												"e-wallet" &&
											user &&
											user?.id === transferForm?.values?.userTo?.user?.id
												? true
												: false
										}
										readonly={
											transferForm?.values?.cryptohubTo?.accountType ===
												"e-wallet" &&
											user &&
											user?.id === transferForm?.values?.userTo?.user?.id
												? true
												: false
										}
										width={"100%"}
										noOptionsMessage={
											isLoadingUsers && usersAccountOptions?.length === 0
												? t("swap-modal.origin.loading-accounts")
												: undefined
										}
										onChange={(option) => {
											refetchCryptoHubInfoBalances();
											setUserTo(option.value);
											transferForm.setFieldValue("cryptohubTo", {
												...transferForm.values.cryptohubTo,
												...{
													balances: option.user.userBalances.reduce(
														(items: any[], item: any) => {
															items[item.currency.currency] = item;
															return items;
														},
														{}
													),
												},
											});

											if (
												option.value === transferForm?.values?.userFrom?.value
											) {
												transferForm.setFieldValue("userFrom", null);
											}

											transferForm.setFieldValue("userTo", option);
										}}
										loadOptions={onChangeSearch}
										defaultOptions={usersAccountToOptions}
									/>
								</>
							)}

							{transferForm?.values?.cryptohubTo?.accountType ===
								"external-address-out" && (
								<>
									<Select
										size={"large"}
										label={t("transfer-modal.network")}
										labelStyle="default"
										inputWidth={"100%"}
										placeholder={""}
										iconSize={28}
										iconSet={"currency"}
										isSearchable={false}
										options={
											networkOptions?.filter((network) =>
												transferForm?.values?.currency?.networks?.includes(
													network.value
												)
											) || []
										}
										value={transferForm?.values?.externalNetworkOut}
										readonly={
											networkOptions?.filter((network) =>
												transferForm?.values?.currency?.networks?.includes(
													network.value
												)
											).length === 1
										}
										onChange={(value) => {
											transferForm.setFieldValue("externalNetworkOut", value);
										}}
										paddingRight={"10px"}
									/>

									<View>
										<Input
											size={"large"}
											label={t(
												"transfer-modal.external-address-out.input-title"
											)}
											placeholder={
												transferForm?.values?.externalNetworkOut?.walletConfig
													?.placeholder
													? transferForm?.values?.externalNetworkOut
															?.walletConfig?.placeholder
													: "0x4817..."
											}
											value={transferForm?.values?.externalAddressOut}
											onChange={(value: string) => {
												transferForm.setFieldValue("externalAddressOut", value);
											}}
											rightAddon={{
												buttons: [
													<Icon
														{...getWalletValidationIconProps()}
														fontSize={"16px"}
													/>,
												],
											}}
										/>

										<View
											style={{
												display: "flex",
												position: "absolute",
												right: 0,
												height: 16,
												flexDirection: "row",
												alignItems: "center",
											}}
										>
											<View style={[styles.inputTopAvailable]}>
												<Text fontStyle={"bold"} size={"12px"}>
													min
												</Text>
												<Text size={"12px"} variant={"gray"}>
													{transferForm?.values?.currency?.withdraw?.networks.find(
														(network) =>
															network.nameId ===
															transferForm?.values?.externalNetworkOut?.value
													)?.amountMin
														? CurrencyUtil.formatCurrency(
																transferForm?.values?.currency?.withdraw?.networks.find(
																	(network) =>
																		network.nameId ===
																		transferForm?.values?.externalNetworkOut
																			?.value
																)?.amountMin,
																TCurrencySymbol?.[
																	transferForm?.values?.currency?.value
																]
														  )
														: "-"}
												</Text>
												<Text fontStyle={"bold"} size={"12px"}>
													/
												</Text>
												<Text fontStyle={"bold"} size={"12px"}>
													Network Fee
												</Text>
												<Text size={"12px"} variant={"gray"}>
													{transferForm?.values?.currency?.withdraw?.networks.find(
														(network) =>
															network.nameId ===
															transferForm?.values?.externalNetworkOut?.value
													)?.amountFee
														? CurrencyUtil.formatCurrency(
																transferForm?.values?.currency?.withdraw?.networks.find(
																	(network) =>
																		network.nameId ===
																		transferForm?.values?.externalNetworkOut
																			?.value
																)?.amountFee,
																TCurrencySymbol?.[
																	transferForm?.values?.currency?.value
																]
														  )
														: "-"}
												</Text>
											</View>
										</View>
									</View>
								</>
							)}

							{transferForm?.values?.cryptohubTo?.accountType ===
								"external-pix" && (
								<Input
									size={"large"}
									paddingRight={"176px"}
									label={t("transfer-modal.external-pix.input-title")}
									value={
										externalPixKeyType?.value === "TELEFONE"
											? Util.formatPhone(transferForm.values.externalPix)
											: externalPixKeyType?.value === "CPF" ||
											  externalPixKeyType?.value === "CNPJ"
											? Util.maskCpfOrCnpj(transferForm.values.externalPix)
											: null
									}
									onChange={(value: string) => {
										transferForm.setFieldValue("externalPix", value);
									}}
									maxLength={
										externalPixKeyType?.value === "TELEFONE" ? 15 : undefined
									}
									rightAddon={{
										buttons: [
											<Select
												size={"medium"}
												inputMinWidth={"112px"}
												options={pixKeyTypes}
												value={externalPixKeyType}
												onChange={async (keyType) => {
													transferForm.setFieldValue("externalPix", "");
													setExternalPixKeyType(keyType);
													transferForm.setFieldValue(
														"externalPixKeyType",
														keyType
													);
												}}
											/>,
										],
									}}
								/>
							)}
						</>

						<TitleTransferModal
							title={t("transfer-modal.amount")}
							icon={"BoxArrowInDown"}
							rightAddon={
								<>
									{transferForm?.values?.cryptohubFrom?.accountType ===
									"e-wallet" ? (
										<View style={[styles.inputTopAvailable]}>
											<Text fontStyle={"bold"} size={"12px"}>
												{t("transfer-modal.available")}
											</Text>
											<Text size={"12px"} variant={"gray"}>
												{balanceVisible
													? CurrencyUtil.formatCurrency(
															userFromBalance,
															TCurrencySymbol?.[
																transferForm.values.currency.value
															]
													  )
													: transferForm.values.currency.type === "fiat"
													? `R$ ***,**`
													: `***,**`}
											</Text>
										</View>
									) : (
										<View style={[styles.inputTopAvailable]}>
											<Text fontStyle={"bold"} size={"12px"}>
												{t("transfer-modal.available")}
											</Text>
											<Text size={"12px"} variant={"gray"}>
												{balanceVisible
													? CurrencyUtil.formatCurrency(
															transferForm?.values?.cryptohubFrom?.balances?.[
																transferForm.values.currency.value
															]?.available,
															TCurrencySymbol?.[
																transferForm.values.currency.value
															]
													  )
													: transferForm.values.currency.type === "fiat"
													? `R$ ***,**`
													: `***,**`}
											</Text>
										</View>
									)}
								</>
							}
						/>

						<Input
							autoComplete={"off"}
							paddingRight={"176px"}
							size={"large"}
							coin={
								transferForm.values.currency.value === TCurrencySymbol.BRL
									? "BRL"
									: "BTC"
							}
							value={transferForm.values.amount}
							onChange={async (value) => {
								await transferForm.setFieldValue("amount", value);
							}}
							decimalConfig={{
								decimalScale:
									transferForm.values.currency.value === TCurrencySymbol.BRL
										? 2
										: 6,
								decimalsLimit:
									transferForm.values.currency.value === TCurrencySymbol.BRL
										? 2
										: 6,
							}}
							rightAddon={{
								buttons: [
									<MaxValueButton
										onPress={() => {
											transferForm.setFieldValue(
												"amount",
												transferForm?.values?.cryptohubFrom?.balances?.[
													transferForm.values.currency.value
												]?.available
											);
										}}
										disabled={false}
									/>,
									<Select
										size={"medium"}
										inputMinWidth={"112px"}
										selectPadding={"0px"}
										placeholder={""}
										iconSize={24}
										iconSet={"currency"}
										options={
											transferForm.values.cryptohubFrom &&
											transferForm.values.cryptohubTo
												? transferForm?.values?.cryptohubFrom?.accountType ===
														"baas" ||
												  transferForm?.values?.cryptohubTo?.accountType ===
														"baas"
													? currencyFiatOptions
													: currencyOptions
												: []
										}
										onChange={async (currency) => {
											setCurrentCurrency(currency.value);

											const updatedCryptoHubAccountsAvailable = [
												...parsedAccounts.map((account) => ({
													...account,
													subLabel:
														account.balances && currency.value
															? CurrencyUtil.formatCurrency(
																	account?.balances[currency.value]?.available,
																	TCurrencySymbol?.[currency.value]
															  )
															: undefined,
												})),
												currency.value !== "BRL" && externalAccount,
											];

											if (
												user ||
												transferForm?.values?.cryptohubFrom?.accountType ===
													"e-wallet"
											) {
												await transferForm.setFieldValue("cryptohubFrom", {
													...updatedCryptoHubAccountsAvailable.find(
														(account) =>
															account.value ===
															transferForm?.values?.cryptohubFrom?.value
													),
													...{
														balances:
															transferForm?.values?.userFrom?.user?.userBalances.reduce(
																(items: any[], item: any) => {
																	items[item.currency.currency] = item;
																	return items;
																},
																{} as Record<string, any>
															),
													},
												});
											} else {
												await transferForm.setFieldValue(
													"cryptohubFrom",
													updatedCryptoHubAccountsAvailable.find(
														(account) =>
															account.value ===
															transferForm?.values?.cryptohubFrom?.value
													)
												);
											}

											if (
												user ||
												transferForm?.values?.cryptohubTo?.accountType ===
													"e-wallet"
											) {
												await transferForm.setFieldValue("cryptohubTo", {
													...updatedCryptoHubAccountsAvailable.find(
														(account) =>
															account.value ===
															transferForm?.values?.cryptohubTo?.value
													),
													...{
														balances:
															transferForm?.values?.userTo?.user?.userBalances.reduce(
																(items: any[], item: any) => {
																	items[item.currency.currency] = item;
																	return items;
																},
																{} as Record<string, any>
															),
													},
												});
											} else {
												await transferForm.setFieldValue(
													"cryptohubTo",
													updatedCryptoHubAccountsAvailable.find(
														(account) =>
															account.value ===
															transferForm?.values?.cryptohubTo?.value
													)
												);
											}

											if (
												user ||
												transferForm?.values?.cryptohubTo?.accountType ===
													"external-address-out"
											) {
												transferForm.setFieldValue(
													"externalNetworkOut",
													networkOptions?.filter((network) =>
														currency?.networks?.includes(network.value)
													)[0]
												);
											}

											await transferForm.setFieldValue("amount", "");
											transferForm.setFieldValue("currency", currency);
										}}
										disabled={
											isLoadingAvailableCryptoHubInfoBalances ||
											(transferForm?.values?.cryptohubFrom?.accountType ===
												"baas" &&
												currencyFiatOptions.length <= 1) ||
											(transferForm?.values?.cryptohubTo?.accountType ===
												"baas" &&
												currencyFiatOptions.length <= 1) ||
											currency
												? true
												: false
										}
										readonly={
											isLoadingAvailableCryptoHubInfoBalances ||
											(transferForm?.values?.cryptohubFrom?.accountType ===
												"baas" &&
												currencyFiatOptions.length <= 1) ||
											(transferForm?.values?.cryptohubTo?.accountType ===
												"baas" &&
												currencyFiatOptions.length <= 1) ||
											currency
												? true
												: false
										}
										value={transferForm.values.currency}
										menuPortalTarget={modalRef?.current}
									/>,
								],
							}}
						/>
					</View>

					<View
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 12,
						}}
					>
						<DetailsCardTransferModal
							title={"..:: " + t("transfer-modal.details.cryptohub-from")}
							cryptohubData={transferForm?.values?.cryptohubFrom}
							userData={transferForm?.values?.userFrom}
						/>

						<DetailsCardTransferModal
							title={"..:: " + t("transfer-modal.details.cryptohub-to")}
							cryptohubData={transferForm?.values?.cryptohubTo}
							userData={transferForm?.values?.userTo}
							externalAddressData={{
								address: transferForm?.values?.externalAddressOut,
								network: transferForm?.values?.externalNetworkOut?.label,
							}}
						/>

						<TransferHistoryCard title={"..:: Histórico"} />

						{confirmationModalVisible ? (
							<ConfirmationModal
								// cryptohubFromData={transferForm?.values?.cryptohubFrom}
								// cryptohubToData={transferForm?.values?.cryptohubTo}
								// userFromData={transferForm?.values?.userFrom}
								// userToData={transferForm?.values?.userTo}
								// amount={transferForm?.values?.amount}
								// currency={transferForm?.values?.currency?.value}
								// externalPix={transferForm?.values?.externalPix}
								onClose={handleCloseConfirmationModal}
								handleConfirm={handleConfirmTransfer}
								data={transferForm?.values}
								transferStatus={transferStatus}
							/>
						) : null}
					</View>
				</View>
			</Modal>

			{depositModalConfig.visible && user && (
				<DepositModal
					onClose={closeDepositModal}
					currency={depositModalConfig.currency}
					user={user}
				/>
			)}
		</>
	);
};
