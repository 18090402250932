import moment from "moment-timezone";
import { isDate, parse } from "date-fns";
import { DefaultFilter } from "../models/DefaultFilter";
import { DocStatusEnum } from "../enums/doc-status.enum";
import { TCurrencies, TCurrencySymbol } from "../types/currency";
import { UserTypeEnum } from "../enums/UserType.enum";
import { TenantConfig } from "../services/multi-tenancy/types";
import { isYesOrNoEnum } from "../types/types";
import { BaasPixKeyTypeEnum } from "../../src/components/TreasurySwapModal/CryptoHubTransferModal";
import { FixedNumber, BigNumber } from "@ethersproject/bignumber";
import { parseUnits, formatUnits } from "ethers/lib/utils";

interface signatureModel {
	[key: string]: string;
}

const signatures: signatureModel = {
	JVBERi0: "application/pdf",
	R0lGODdh: "image/gif",
	R0lGODlh: "image/gif",
	iVBORw0KGgo: "image/png",
	"/9j/": "image/jpg",
};

// export enum TStack {
// 	DEVELOPMENT = "development",
// 	SANDBOX = "sandbox",
// 	PRODUCTION = "production"
// }

export default class Util {
	static tenant: TenantConfig;

	static block = true;
	static json: any;

	static moment = moment();

	static getEnvironment(): "development" | "sandbox" | "production" {
		return window.location.hostname.indexOf(".local") !== -1 ||
			window.location.hostname.indexOf("-development") !== -1
			? "development"
			: window.location.hostname.indexOf("-sandbox") !== -1
			? "sandbox"
			: "production";
	}

	static SizeLimiter(size: number, min = 11, max = 0): number {
		if (size < min) {
			return min;
		}
		if (max && max < size) {
			return max;
		}
		return size;
	}

	/**
	 * @param postalCode
	 * @return string
	 */
	public static formatPostalCode(postalCode: string) {
		if (Util.isNullOrEmpty(postalCode) || postalCode.length != 8) {
			return postalCode;
		}

		return postalCode.substr(0, 5) + "-" + postalCode.substr(5);
	}

	/**
	 * @description
	 * Format Wallet Address
	 * @param address
	 */
	public static formatWalletAddress(
		address: string,
		retireStrings = 10
	): string {
		return (
			address.substring(0, address.length / 2 - retireStrings) +
			"..." +
			address.substring(address.length / 2 + retireStrings, address.length)
		);
	}

	public static detectMimeType = (base64: string) => {
		if (Util.isNullOrEmpty(base64)) {
			return base64;
		}
		type signatureTypes = keyof signatureModel;

		for (const signature in signatures) {
			if (base64.indexOf(signature) === 0) {
				return signatures[signature as signatureTypes];
			}
		}
	};

	static async waitFor(
		cb: Function,
		options: { timeout?: number; tick?: number } = {
			timeout: 10 * 1000,
			tick: 100,
		},
		...args: any[]
	) {
		if (typeof cb === "function") {
			let _optionsTimeout = options?.timeout ?? 10 * 1000;
			let _optionsTick = options?.tick ?? 100;
			let _timeoutCount: number = Number(_optionsTimeout);

			return new Promise((resolve, reject) => {
				const _check = () => {
					if (cb(...args)) {
						return resolve(true);
					} else if ((_timeoutCount -= _optionsTick) < 0) {
						console.log(
							"Util.waitFor timeout! (more than " + _optionsTimeout + "ms)"
						);
						return reject("timeout");
					} else {
						setTimeout(_check, _optionsTick);
					}
				};
				setImmediate(_check);
				// setTimeout(check, _optionsTick);
			});
		}

		return Promise.reject(new Error("Unsupported target type: " + typeof cb));
	}

	static parseScientific(num: string): string {
		// If the number is not in scientific notation return it as it is.
		if (!/\d+\.?\d*e[+-]*\d+/i.test(num)) {
			return num;
		}

		// Remove the sign.
		const numberSign = Math.sign(Number(num));
		num = Math.abs(Number(num)).toString();

		// Parse into coefficient and exponent.
		const [coefficient, exponent] = num.toLowerCase().split("e");
		let zeros = Math.abs(Number(exponent));
		const exponentSign = Math.sign(Number(exponent));
		const [integer, decimals] = (
			coefficient.indexOf(".") != -1 ? coefficient : `${coefficient}.`
		).split(".");

		if (exponentSign === -1) {
			zeros -= integer.length;
			num =
				zeros < 0
					? integer.slice(0, zeros) + "." + integer.slice(zeros) + decimals
					: "0." + "0".repeat(zeros) + integer + decimals;
		} else {
			if (decimals) zeros -= decimals.length;
			num =
				zeros < 0
					? integer + decimals.slice(0, zeros) + "." + decimals.slice(zeros)
					: integer + decimals + "0".repeat(zeros);
		}

		return numberSign < 0 ? "-" + num : num;
	}

	static parseUnits(
		value: string | number | FixedNumber | BigNumber,
		format: number = 20
	): BigNumber {
		if (
			value === undefined ||
			(typeof value === "number" && Number.isNaN(value))
		)
			return undefined;
		else if (value === null || value === "") return null;
		return BigNumber.isBigNumber(value)
			? value
			: parseUnits(Util.parseScientific(`${+`${value}`}`), format ?? 20);
	}

	static formatUnits(
		value: string | number | FixedNumber | BigNumber,
		format: number = 20
	): string {
		return formatUnits(Util.parseUnits(value, format ?? 20), format ?? 20);
	}

	static FixedNumber(value: any, format: number = 20): FixedNumber {
		if (
			Util.isNullOrEmpty(value) ||
			(typeof value === "number" && Number.isNaN(value)) ||
			FixedNumber.isFixedNumber(value)
		)
			return value;

		let _parseScientific = Util.parseScientific(`${+`${value}`}`);
		if ((_parseScientific?.split(".")?.[1]?.length ?? 0) > format) {
			_parseScientific = `${
				_parseScientific?.split(".")?.[0]
			}.${_parseScientific?.split(".")?.[1].substring(0, format)}`;
		}

		// try {
		// 	const _x = FixedNumber.from(Util.parseScientific(`${+(`${value}`)}`), format);
		// } catch (e) {
		// 	console.log(
		// 		`::Util.FixedNumber::`,
		// 		'[ERROR]', e.message.toString().indexOf("fractional component exceeds decimals") !== -1 ? `fractional exceeds decimals` : e.message,
		// 		{
		// 			value,
		// 			format,
		// 			decimals: Util.parseScientific(`${+(`${value}`)}`)?.split(".")?.[1]?.length,
		// 			valueStr: `${+(`${value}`)}`,
		// 			parseScientific: Util.parseScientific(`${+(`${value}`)}`),
		// 			output: FixedNumber.from(_parseScientific, format),
		// 		}
		// 	)
		// }

		return FixedNumber.from(_parseScientific, format);
	}

	static toFixed(
		value: string | number | FixedNumber,
		decimals: number
	): string {
		if (
			value === undefined ||
			(typeof value === "number" && Number.isNaN(value))
		)
			return undefined;
		else if (value === null || value === "") return null;
		return (+`${Util.FixedNumber(value)}`).toLocaleString("en", {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
			useGrouping: false,
		});
	}

	static truncate(
		value: string | number | FixedNumber,
		decimals: number
	): string {
		if (
			value === undefined ||
			(typeof value === "number" && Number.isNaN(value))
		)
			return undefined;
		else if (value === null || value === "") return null;
		return Util.toFixed(
			Math.trunc(
				+`${Util.formatUnits(
					Util.parseUnits(`${Util.FixedNumber(value)}`).mul(
						BigNumber.from(10).pow(decimals)
					)
				)}`
			) /
				10 ** decimals,
			decimals
		);
	}

	static tryCastObjectToString(input: any): string {
		return Util.isNullOrEmpty(input)
			? input
			: (typeof input === "object" ? JSON.stringify(input) : input).toString();
	}

	static tryCastStringToObject(input: any): any {
		return Util.isNullOrEmpty(input) || typeof input !== "string"
			? input
			: input.trim().startsWith("{")
			? JSON.parse(input)
			: input;
	}

	static isStringJsonValid(input: string): boolean {
		try {
			JSON.parse(input);
			return true;
		} catch {
			return false;
		}
	}

	/**
	 *
	 * @param inputText
	 */
	static isNullOrEmpty(inputText: any): boolean {
		return inputText === null || inputText === "" || inputText === undefined;
	}

	static formatDate(
		date?: moment.MomentInput,
		timezone?: string,
		format?: string,
		locale: string = "pt-BR",
		addTime: { amount: number; unit: "h" | "m" } = {
			amount: 0,
			unit: "h",
		}
	): string {
		return !!date
			? moment(date)
					.add(addTime.amount, addTime.unit as any)
					.locale(locale)
					.tz(timezone || "Brazil/East")
					.format(format || "L LTS")
			: moment()
					.add(addTime.amount, addTime.unit as any)
					.locale(locale)
					.tz(timezone || "Brazil/East")
					.format(format || "L LT");
	}

	static isValidUrl(urlString: string = "") {
		let url;

		try {
			url = new URL(urlString);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}

	static CurrencyFormat(
		value: number,
		symbol = "R$ ",
		position: "left" | "right" = "left",
		specificDecimals: null | number = 2
	) {
		const decimals =
			specificDecimals != null ? specificDecimals : this.getDecimals(value);

		return (
			(position == "left" ? symbol : "") +
			value.toLocaleString("pt-BR", {
				minimumFractionDigits: decimals,
				useGrouping: false,
			}) +
			(position == "right" ? symbol : "")
		);
	}

	static DecimalFormat(
		value: number | string,
		specificDecimals: null | number = null,
		minimumDecimals: number = 3,
		returnNumber = false
	): number | string {
		let number = value;
		if (typeof number === "string") {
			number = parseFloat(number);
		}

		const formattedNumber = specificDecimals
			? number.toFixed(specificDecimals)
			: number.toFixed(this.getDecimals(number, minimumDecimals));

		return returnNumber ? parseFloat(formattedNumber) : formattedNumber;
	}

	static getDecimals(value: number, minimumDecimals: number = 3) {
		const decimals =
			value >= 1 || value == 0
				? minimumDecimals
				: value.toString().search(/[1-9]/g);

		return decimals <= minimumDecimals ? minimumDecimals : decimals;
	}

	private static percentageCalculation(max: number, val: number): number {
		return max * (val / 100);
	}

	private static fontCalculation(height: number, width: number, val: number) {
		const widthDimension = height > width ? width : height;
		const aspectRatioBasedHeight = (16 / 9) * widthDimension;
		return this.percentageCalculation(
			Math.sqrt(
				Math.pow(aspectRatioBasedHeight, 2) + Math.pow(widthDimension, 2)
			),
			val
		);
	}

	static responsiveVariables(json: any, height: number, width: number) {
		if (this.json !== json) {
			const obj: any = {};

			for (let i = 0; i < json.fontSize.length; i++) {
				obj["fontSize" + json.fontSize[i]] = this.fontCalculation(
					height,
					width,
					(100 * parseInt(json.fontSize[i])) / 1920
				);
			}

			for (let i = 0; i < json.width.length; i++) {
				obj["widthSize" + json.width[i]] =
					(width * parseInt(json.width[i])) / 1920;
			}

			for (let i = 0; i < json.height.length; i++) {
				obj["heightSize" + json.height[i]] =
					(height * parseInt(json.height[i])) / 1080;
			}

			this.json = json;
			return obj;
		}
	}

	// static isValidEmail(email: string): boolean {
	// 	const re =
	// 		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// 	if (!re.test(String(email).toLowerCase())) return false;
	// 	return true;
	// }

	static isValidEmail(email: string) {
		if (/\S+@\S+\.\S+/.test(email)) {
			return true;
		}

		return false;
	}

	static responsiveOnChange(
		json: any,
		obj: any,
		height: number,
		width: number
	) {
		for (let i = 0; i < json.fontSize.length; i++) {
			obj["fontSize" + json.fontSize[i]] = this.fontCalculation(
				height,
				width,
				(100 * parseInt(json.fontSize[i])) / 1920
			);
		}

		for (let i = 0; i < json.width.length; i++) {
			obj["widthSize" + json.width[i]] =
				(width * parseInt(json.width[i])) / 1920;
		}

		for (let i = 0; i < json.height.length; i++) {
			obj["heightSize" + json.height[i]] =
				(height * parseInt(json.height[i])) / 1080;
		}

		return obj;
	}

	static indicatorSizeScroll(
		heightScrollContent: number,
		heightScrollVisible: number
	) {
		return heightScrollContent > heightScrollVisible
			? (heightScrollVisible * heightScrollVisible) / heightScrollContent
			: heightScrollVisible;
	}

	static differenceScroll(indicatorSize: number, heightScrollVisible: number) {
		return heightScrollVisible > indicatorSize
			? heightScrollVisible - indicatorSize
			: 1;
	}

	static getItemTitle = (item: any, language: string) => {
		return item ? item[`title${language}`] : "";
	};

	static removeMask = (value: string) => {
		return value.replace(/[^a-zA-Z0-9]/g, "");
	};

	static onlyAlphanumeric = (value: string) => {
		return value ? value.replace(/[\W_]+/g, "") : "";
	};

	static getFirstName = (fullname: string) => {
		const name = fullname || "";
		return name.substring(
			0,
			name.indexOf(" ") > 0 ? name.indexOf(" ") : name.length
		);
	};

	static getPageCount(count: number, limit: number) {
		return Math.ceil(count / limit);
	}

	static parseDate(
		value: string,
		originalValue: string,
		format = "dd/MM/yyyy"
	) {
		return isDate(originalValue)
			? originalValue
			: parse(originalValue, format, new Date());
	}

	static defineSearch(filter?: DefaultFilter): string {
		let search: any = [];
		const functionalWords = ["like", "equal", "notequal", "between", "in"];

		const mountLayer = (values: any) => {
			const _items = Object.keys(values).reduce((items, key) => {
				let value = values[key];
				const valueKeys = !!value && Object.keys(value);

				if (
					valueKeys &&
					(valueKeys.length > 1 || !functionalWords.includes(valueKeys[0]))
				) {
					if (
						typeof value === "object" &&
						!Array.isArray(value) //add
					) {
						value = mountLayer(value);
					}
				}

				if (value) {
					items[key] = valueKeys[0] === "in" ? value["in"] : value;
				}

				return items;
			}, {});

			if ((_items ?? ({} as any)).name?.like === "") {
				delete (_items ?? ({} as any)).name;
			}

			return Object.keys(_items).length === 0 ? [] : [_items];
		};

		if (filter?.search) {
			search = mountLayer(filter?.search);
		}

		return search === null ? "" : JSON.stringify(search);
	}

	// static defineSearch(filter?: DefaultFilter): string {
	// 	let search: any = [];
	// 	const functionalWords = ["like", "equal", "notequal", "between", "in"];

	// 	const mountLayer = (values: any) => {
	// 		const _items = Object.keys(values).reduce((items, key) => {
	// 			let value = values[key];
	// 			const valueKeys = !!value && Object.keys(value);

	// 			if (
	// 				valueKeys &&
	// 				(valueKeys.length > 1 || !functionalWords.includes(valueKeys[0]))
	// 			) {
	// 				if (
	// 					typeof value === "object" &&
	// 					!Array.isArray(value) //add
	// 				) {
	// 					value = mountLayer(value);
	// 				}
	// 			}

	// 			if (value) {
	// 				items[key] = valueKeys[0] === "in" ? value["in"] : value;
	// 			}

	// 			return items;
	// 		}, {});

	// 		if ((_items ?? ({} as any)).name?.like === "") {
	// 			delete (_items ?? ({} as any)).name;
	// 		}

	// 		return Object.keys(_items).length === 0 ? [] : [_items];
	// 	};

	// 	if (filter?.search) {
	// 		search = mountLayer(filter?.search);
	// 	}

	// 	console.log("defineSearch", search);

	// 	return search === null ? "" : JSON.stringify(search);
	// }

	static getTranslatedProperty(
		object: any,
		propertyName: string,
		currentLanguage: string
	): string {
		if (!object) {
			return "";
		}

		const formattedLanguage =
			currentLanguage[0].toUpperCase() + currentLanguage.slice(1);
		const value = object[propertyName + formattedLanguage];
		return value || object[propertyName] || "";
	}

	/**
	 * Format Phone
	 * @param value
	 */
	static formatPhone(value: string | number): string {
		let formattedPhone = "";

		if (value) {
			const phone = value.toString().replace(/\D/g, "");

			if (phone.length > 12) {
				formattedPhone = phone.replace(
					/(\d{2})?(\d{2})?(\d{5})?(\d{4})/,
					"+$1 ($2) $3-$4"
				);
			} else if (phone.length > 11) {
				formattedPhone = phone.replace(
					/(\d{2})?(\d{2})?(\d{4})?(\d{4})/,
					"+$1 ($2) $3-$4"
				);
			} else if (phone.length > 10) {
				formattedPhone = phone.replace(/(\d{2})?(\d{5})?(\d{4})/, "($1) $2-$3");
			} else if (phone.length > 9) {
				formattedPhone = phone.replace(/(\d{2})?(\d{4})?(\d{4})/, "($1) $2-$3");
			} else if (phone.length > 5) {
				formattedPhone = phone.replace(
					/^(\d{2})?(\d{4})?(\d{0,4})/,
					"($1) $2-$3"
				);
			} else if (phone.length > 1) {
				formattedPhone = phone.replace(/^(\d{2})?(\d{0,5})/, "($1) $2");
			} else {
				if (phone !== "") {
					formattedPhone = phone.replace(/^(\d*)/, "($1");
				}
			}
		}

		return formattedPhone;
	}

	/**
	 * Format CNPJ OR COMPANY ID
	 * @param text
	 */
	static maskCpfOrCnpj(text: string): string {
		if (Util.isNullOrEmpty(text)) {
			return text;
		}
		if (/^[0-9]+\.?[0-9]+\.?[0-9]*$/.test(text) && text.length < 14) {
			const mask: any = text
				.replace(/\D/g, "")
				.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
			return !mask[2]
				? mask[1]
				: `${mask[1]}.${mask[2]}${mask[3] ? "." + mask[3] : ""}${
						mask[4] ? "-" + mask[4] : ""
				  }`.replaceAll(/\s/g, "");
		} else if (
			((/^[0-9]+\.?[0-9]+\.?[0-9]+-?[0-9]+[a-z0-9]+$/.test(text) ||
				/^[0-9]+\.?[0-9]+\.?[0-9]+\/?[0-9]-?[0-9]+$/.test(text)) &&
				text.length > 14) ||
			(text.match(/^[0-9]+$/) && text.length == 14)
		) {
			const mask: any = text
				.replace(/\D/g, "")
				.match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
			const teste = !mask[2]
				? mask[1]
				: `${mask[1]}.${mask[2]}${mask[3] ? "." + mask[3] : ""}
      ${mask[4] ? "/" + mask[4] + "-" : ""}
      ${mask[5] ? mask[5] : ""}`;
			return teste.replace(/ /g, "").replaceAll(/\s/g, "");
		} else if (/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{3}$/.test(text)) {
			return text.slice(0, -1);
		} else if (/[a-zA-Z]/g.test(text) || text.indexOf(" ") >= 0) {
			return "";
		} else {
			return text.replaceAll(/\s/g, "");
		}
	}

	static getDocumentProofStatus(
		documentProofApproved: string,
		documentProofApprovedAt?: string,
		documentProofAt?: string,
		documentProofReason?: string,
		documentProofReprovedAt?: string
	): DocStatusEnum {
		if (Util.isNullOrEmpty(documentProofAt)) {
			return DocStatusEnum.NOT_SENT;
		}
		if (documentProofApproved === isYesOrNoEnum.YES) {
			return DocStatusEnum.APPROVED;
		}
		if (
			documentProofApproved === "N" &&
			!Util.isNullOrEmpty(documentProofReprovedAt)
		) {
			return DocStatusEnum.REPROVED;
		}
		if (!Util.isNullOrEmpty(documentProofAt)) {
			return DocStatusEnum.AWAITING;
		}
		if (
			!Util.isNullOrEmpty(documentProofReason) &&
			!Util.isNullOrEmpty(documentProofReprovedAt)
		) {
			return DocStatusEnum.REPROVED;
		}

		return DocStatusEnum.REPROVED;
	}

	/**
	 *
	 * @param amount
	 * @param currency
	 */
	static formatCurrency(
		amount: number,
		currency: TCurrencySymbol,
		showSymbol: boolean = true
	): string {
		if (!isNaN(amount)) {
			amount = Number(amount);
		}

		const _currency = TCurrencies.find((value) => value.currency === currency);
		const fixCurrency = Util.isNullOrEmpty(_currency) ? 0 : _currency?.decimals;
		switch (currency) {
			case TCurrencySymbol.BRL:
				return Number(amount)
					.toLocaleString("pt-BR", {
						minimumFractionDigits: 2,
						style: "currency",
						currency: "BRL",
						useGrouping: true,
					})
					?.replace(!showSymbol ? "R$" : "", "");
			default:
				return (
					Number(amount).toFixed(fixCurrency) +
					(showSymbol ? " " + currency : "")
				);
		}
	}

	static stripZeros(str: string): string {
		return str.replace(/(^0+(?=\d))|(,?0+$)/g, "");
	}

	static roundTokenAmount(
		value: number | string,
		decimals = 5,
		roundType: "ceil" | "floor" = "floor"
	) {
		const decimalPow = 10 ** decimals;
		return (
			Math[roundType]((Number(value) + Number.EPSILON) * decimalPow) /
			decimalPow
		);
	}

	static formatNumber(
		value: string,
		from: TCurrencySymbol = TCurrencySymbol.BRL
	): number {
		let num = 0;
		switch (from) {
			case TCurrencySymbol.BRL:
				num = Number(
					value
						.replace(/\./g, "")
						.replace(/,/, ".")
						.replace(/[^\d.,]/g, "")
				);
				break;
			default:
				num = Number(value.replace(/,/g, "").replace(/[^\d.,]/g, ""));
		}

		return num;
	}

	static isUUID(value: string) {
		return /[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}/.test(value);
		// return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(value);
	}

	static identifyPixKeyType(key: string): BaasPixKeyTypeEnum {
		if (Util.isNullOrEmpty(key)) return null;

		if (Util.isUUID(key)) return BaasPixKeyTypeEnum.CHAVE_ALEATORIA;

		if (Util.isValidEmail(key)) return BaasPixKeyTypeEnum.EMAIL;

		if (Validator.isCpfValid(key)) return BaasPixKeyTypeEnum.CPF;

		if (Validator.isCnpjValid(key)) return BaasPixKeyTypeEnum.CNPJ;

		if (key.startsWith("+55") && Validator.isValidMobileNumber(key))
			return BaasPixKeyTypeEnum.TELEFONE;

		return null;
	}

	static mountDefaultUserSearch(text: string, search: any) {
		search = {
			...search,
			name: { like: "" },
			email: "",
			personCompanyId: { like: "" },
		};

		// const textWithoutMask = text?.replace(/\D/g, "");
		const textWithoutMask = text
			?.replaceAll("-", "")
			.replaceAll("/", "")
			.trim();
		const isNumber = textWithoutMask && !isNaN(Number(textWithoutMask));

		if (isNumber) {
			search.personCompanyId.like = textWithoutMask;
			delete search.name;
			delete search.email;
		} else {
			delete search.personCompanyId;
			if (text.indexOf("@") !== -1) {
				search.email = { like: text };
				delete search.name;
				delete search.id;
			} else {
				search.name.like = text;
				delete search.email;
				if (text === "") {
					delete search.name;
					delete search.id;
				} else if (
					/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
						text
					)
				) {
					delete search.name;
					search.id = text;
				}
			}
		}

		return search;
	}

	static getUserDocumentIdLabelI18PathByType(type: "all" | UserTypeEnum) {
		const tenantUserDocumentIdType = Util.tenant?.userDocumentId || "default";

		return `general.user-document-id.${tenantUserDocumentIdType}${
			tenantUserDocumentIdType === "default" ? "." + type : ""
		}`;
	}

	static maskBlockchainAddressOrTransactionId(
		addressOrTransactionId: string,
		startLength: number = 8,
		endLength: number = 8
	) {
		if (!addressOrTransactionId) {
			return addressOrTransactionId;
		}

		let _addressOrTransactionId = Util.tryCastStringToObject(
			addressOrTransactionId
		);

		if (_addressOrTransactionId?.["bank"]?.["account"]) {
			_addressOrTransactionId =
				`${_addressOrTransactionId?.["bank"]?.["code"] ?? ""}` +
				` | ${_addressOrTransactionId?.["bank"]?.["branch"] ?? ""}` +
				` | ${_addressOrTransactionId?.["bank"]?.["account"] ?? ""}`;
		} else {
			_addressOrTransactionId =
				_addressOrTransactionId?.["pix"]?.["key"] ??
				_addressOrTransactionId?.["p2p"]?.["account"] ??
				_addressOrTransactionId?.["internal"]?.["id"] ??
				_addressOrTransactionId?.["internal"]?.["email"] ??
				Util.tryCastObjectToString(_addressOrTransactionId);
		}

		if (_addressOrTransactionId?.length <= (startLength + endLength)) {
			return _addressOrTransactionId;
		}

		const initial = _addressOrTransactionId.substring(0, startLength);
		const end = _addressOrTransactionId.slice(-endLength);

		return initial + "..." + end;
	}

	static validateIp(ip: string) {
		let ipv4 =
			/(([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])/;

		// Regex expression for validating IPv6
		let ipv6 = /((([0-9a-fA-F]){1,4})\:){7}([0-9a-fA-F]){1,4}/;

		// Checking if it is a valid IPv4 addresses
		if (ip.match(ipv4)) return true;
		// Checking if it is a valid IPv6 addresses
		else if (ip.match(ipv6)) return true;

		// Return Invalid
		return false;
	}
}

export class Validator {
	/* @TODO: renomear esse nome em portugues */
	static isCpfValid(cpf: string): boolean {
		if (typeof cpf !== "string") return false;
		cpf = cpf.replace(/[\s.-]*/gim, "");
		if (
			!cpf ||
			cpf.length !== 11 ||
			cpf === "00000000000" ||
			cpf === "11111111111" ||
			cpf === "22222222222" ||
			cpf === "33333333333" ||
			cpf === "44444444444" ||
			cpf === "55555555555" ||
			cpf === "66666666666" ||
			cpf === "77777777777" ||
			cpf === "88888888888" ||
			cpf === "99999999999"
		) {
			return false;
		}
		let soma = 0;
		let resto: number;
		for (let i = 1; i <= 9; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
		resto = (soma * 10) % 11;
		if (resto == 10 || resto == 11) resto = 0;
		if (resto != parseInt(cpf.substring(9, 10))) return false;
		soma = 0;
		for (let i = 1; i <= 10; i++)
			soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
		resto = (soma * 10) % 11;
		if (resto == 10 || resto == 11) resto = 0;
		if (resto != parseInt(cpf.substring(10, 11))) return false;
		return true;
	}

	static isValidBirthdate(date: string) {
		const birthdate = moment(date);
		if (isNaN(birthdate.date())) {
			return { isValid: false, feedback: "Please enter a valid date." };
		}
		const minDate = moment().subtract(100, "years");
		const maxDate = moment().subtract(18, "years");
		if (birthdate.isBefore(minDate)) {
			return { isValid: false, feedback: `Enter a date after ${minDate}.` };
		}
		if (birthdate.isAfter(maxDate)) {
			return {
				isValid: false,
				feedback: "You must be of legal age (+18 years) to create account.",
			};
		}

		return { isValid: true, feedback: "birthdate is valid" };
	}

	static isValidMobileNumber(mobileNumber: string): boolean {
		mobileNumber = mobileNumber
			.replace(/\(/g, "")
			.replace(/\)/g, "")
			.replace(/-/g, "")
			.replace(/ /g, "");
		return mobileNumber.length >= 10;
	}

	static isValidPostalCode(postalCode: string): boolean {
		const validatePostalCodeRegex = /^[0-9]{8}$/;
		postalCode = postalCode.replace(/-/g, "");
		return validatePostalCodeRegex.test(postalCode);
	}

	static isCnpjValid(value: string): boolean {
		if (!value) return false;

		// Aceita receber o valor como string, número ou array com todos os dígitos
		const isString = typeof value === "string";
		const validTypes =
			isString || Number.isInteger(value) || Array.isArray(value);

		// Elimina valor em formato inválido
		if (!validTypes) return false;

		// Filtro inicial para entradas do tipo string
		if (isString) {
			// Limita ao máximo de 18 caracteres, para CNPJ formatado
			if (value.length > 18) return false;

			// Teste Regex para veificar se é uma string apenas dígitos válida
			const digitsOnly = /^\d{14}$/.test(value);
			// Teste Regex para verificar se é uma string formatada válida
			const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

			// Se o formato é válido, usa um truque para seguir o fluxo da validação
			if (digitsOnly || validFormat) true;
			// Se não, retorna inválido
			else return false;
		}

		// Guarda um array com todos os dígitos do valor
		const match = value.toString().match(/\d/g);
		const numbers = Array.isArray(match) ? match.map(Number) : [];

		// Valida a quantidade de dígitos
		if (numbers.length !== 14) return false;

		// Elimina inválidos com todos os dígitos iguais
		const items = [...new Set(numbers)];
		if (items.length === 1) return false;

		// Cálculo validador
		const calc = (x: number) => {
			const slice = numbers.slice(0, x);
			let factor = x - 7;
			let sum = 0;

			for (let i = x; i >= 1; i--) {
				const n = slice[x - i];
				sum += n * factor--;
				if (factor < 2) factor = 9;
			}

			const result = 11 - (sum % 11);

			return result > 9 ? 0 : result;
		};

		// Separa os 2 últimos dígitos de verificadores
		const digits = numbers.slice(12);

		// Valida 1o. dígito verificador
		const digit0 = calc(12);
		if (digit0 !== digits[0]) return false;

		// Valida 2o. dígito verificador
		const digit1 = calc(13);
		return digit1 === digits[1];
	}

	static isValidUrl(urlString: string = "") {
		let url;

		try {
			url = new URL(urlString);
		} catch (_) {
			return false;
		}

		return url.protocol === "http:" || url.protocol === "https:";
	}
}
