import React, { useCallback, useEffect, useMemo, useState } from "react";
// import { View } from "react-native";
import GeneralCSS from "../../src/global-constants/Styles";
import styles from "./styles";
import Header from "../../src/components/header/header";
import {
	Button,
	Checkbox,
	Icon,
	Input,
	Select,
	Text,
	View,
} from "hubchain-storybook-design-pattern";
import { useTranslation } from "react-i18next";
import SwapModalTitle from "../../src/components/UserSwapModal/components/SwapModalTitle";
import { isMobile } from "react-device-detect";
import SwapChangeTypeButton from "../../src/components/UserSwapModal/components/SwapChangeTypeButton";
import SwapModalDetailRow from "../../src/components/UserSwapModal/components/SwapModalDetailRow";
import { ActivityIndicator } from "react-native";
import { useFormik } from "formik";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import { TCurrencySymbol } from "../../src/types/currency";
import { NetworkNameId } from "../../src/types/network";
import { CurrencyTypeEnum } from "../../src/models/CurrencyModel";
import {
	INetworkOption,
	SwapModalCalculatePairAmountParams,
	SwapModalSwapDirectionEnum,
} from "../../src/components/UserSwapModal/UserSwapModal";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import CurrencyService, {
	INetwork,
} from "../../src/services/currency/currencyService";
import { TenantAppDomainEnum } from "../../src/services/multi-tenancy/types";
import { useMultiTenancy } from "../../src/services/multi-tenancy/multi-tenancy";

import { useLocation } from "react-router-dom";

import SwapService, {
	QuoteSwapDataResponse,
} from "../../src/services/swap/swapService";
import CurrencyUtil from "../../src/utils/CurrencyUtil";
import { useDebouncedValue } from "../../src/hooks/useDebounce";
import moment from "moment";
import { RequestTypeEnum } from "../../src/enums/RequestType.enum";
import { useAuth } from "../../src/services/auth";

interface ICurrencyOption extends SelectOptionModel {
	value: TCurrencySymbol;
	networks: INetwork["nameId"][];
	type: CurrencyTypeEnum;
}

export interface CalcFormData {
	type: "swapExactAmountIn" | "swapExactNetAmountOut";
	amountIn: string;
	currencyIn: ICurrencyOption;
	networkIn: INetworkOption;

	netAmountOut: string;
	currencyOut: ICurrencyOption;
	networkOut: INetworkOption;
}

type ServiceFeeTypes = "0" | "0.005" | "0.01" | "0.02" | "0.03";

interface SwapModalQuoteProps {
	type: "swapExactAmountIn" | "swapExactNetAmountOut";
	entry: "netAmountOut" | "amountIn";
	amountIn?: number;
	netAmountOut?: number;
	currencyIn: TCurrencySymbol;
	currencyOut: TCurrencySymbol;
	networkIn?: string;
	networkOut?: string;
	requestedAt: Date;
}

function useCurrencyParams() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	let currencyIn: any = searchParams.get("currencyIn");
	let currencyOut: any = searchParams.get("currencyOut");
	let network: any = searchParams.get("network");
	let calcDirection;

	if (currencyOut && Object.values(TCurrencySymbol).includes(currencyOut)) {
		currencyOut = currencyOut;
	} else {
		currencyOut = null;
	}

	if (currencyIn && Object.values(TCurrencySymbol).includes(currencyIn)) {
		currencyIn = currencyIn;

		calcDirection =
			currencyIn === TCurrencySymbol.BRL
				? SwapModalSwapDirectionEnum.fromFiatToCrypto
				: "fromCryptoToFiat";
	} else {
		currencyIn = null;
		calcDirection;
		currencyOut = null;
	}

	if (network && Object.values(NetworkNameId).includes(network)) {
		network = network;
	} else {
		network = null;
	}

	if (currencyIn === currencyOut) {
		return {
			currencyIn,
			currencyOut: null,
			calcDirection,
		};
	}

	return {
		currencyIn,
		currencyOut,
		calcDirection,
		network,
	};
}

interface SwapDetailsCardProps {
	quoteData: any; // QuoteSwapDataResponse | undefined;
	isQuoting: boolean;
	serviceFee: ServiceFeeTypes;
	toggleServiceFee: (fee: ServiceFeeTypes) => void;
}

function SwapDetailsCard({
	quoteData,
	isQuoting,
	serviceFee,
	toggleServiceFee,
}: SwapDetailsCardProps) {
	const { t } = useTranslation();

	const quote = quoteData || {};

	const [exchangeTickerPriceCurrency, setExchangeTickerPriceCurrency] =
		useState<{ amountIn: "USD" | "EUR"; netAmountOut: "USD" | "EUR" }>({
			amountIn: "USD",
			netAmountOut: "USD",
		});

	const toggleCurrency = (field: "amountIn" | "netAmountOut") => {
		setExchangeTickerPriceCurrency((state) => ({
			...state,
			[field]: state[field] === "USD" ? "EUR" : "USD",
		}));
	};

	const {
		amountIn = 0, // valor padrão, se amountIn for undefined
		currencyIn = "",
		netAmountOut = 0,
		currencyOut = "",
		networkAmountFee = 0,
		networkCurrencyFee = "",
		servicePercentageFee = 0,
		serviceAmountFee = 0,
		serviceCurrencyFee = "",
		quotes = [],
	} = quote;

	const percentage = serviceFee ? Number(serviceFee) * 100 : "0";

	const details = useMemo(() => {
		let quoteInOut = 0;
		let quoteOutIn = 0;

		if (quotes) {
			quoteInOut = CurrencyUtil.getNumberByValue(
				quotes[currencyIn + currencyOut]
			);
			quoteOutIn = CurrencyUtil.getNumberByValue(
				quotes[currencyOut + currencyIn]
			);
		}

		let exchangeRateInOut = (
			<>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					{CurrencyUtil.formatCurrency(
						currencyIn ? 1 : 0,
						currencyIn,
						true,
						0,
						0
					) + " = "}
				</Text>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					&nbsp;
					{"" +
						CurrencyUtil.formatCurrency(quoteInOut, currencyOut, true, 6, 6)}
				</Text>
			</>
		);

		let exchangeRateOutIn = (
			<>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					{CurrencyUtil.formatCurrency(
						currencyOut ? 1 : 0,
						currencyOut,
						true,
						0,
						0
					) + " = "}
				</Text>
				<Text
					fontStyle={"bold"}
					style={{ overflowWrap: "anywhere", whiteSpace: "nowrap" }}
				>
					&nbsp;
					{"" + CurrencyUtil.formatCurrency(quoteOutIn, currencyIn, true, 6, 6)}
				</Text>
			</>
		);

		return {
			exchangeRateOutIn,
			exchangeRateInOut,
		};
	}, [quote]);

	const FiatPriceTickerView = useCallback(
		({
			field,
			currency,
			calculatedSwapQuote,
		}: {
			field: "amountIn" | "netAmountOut";
			currency: TCurrencySymbol;
			calculatedSwapQuote: QuoteSwapDataResponse;
		}) => {
			const _fiatCurrencyOut: "USD" | "EUR" =
				exchangeTickerPriceCurrency[field];

			let value = 0;

			if (calculatedSwapQuote) {
				const fieldValue = CurrencyUtil.getNumberByValue(
					calculatedSwapQuote[field]
				);

				const ticker =
					calculatedSwapQuote &&
					calculatedSwapQuote.ticker &&
					calculatedSwapQuote.ticker[currency] &&
					calculatedSwapQuote.ticker[currency]![_fiatCurrencyOut]
						? calculatedSwapQuote.ticker[currency]![_fiatCurrencyOut]
						: 0;

				if (ticker > 0) {
					value =
						currency === TCurrencySymbol.BRL
							? fieldValue / ticker
							: fieldValue * ticker;
				}
			}

			return (
				<View>
					<View
						style={{
							display: "flex",
							flexDirection: "row",
							gap: 4,
							alignItems: "center",
						}}
					>
						<Text size={"11px"} variant={"gray"}>
							≈{" "}
							{CurrencyUtil.formatCurrency(
								value,
								TCurrencySymbol.USDT,
								false,
								2,
								2
							)}
						</Text>

						<Button
							size={"table"}
							fillVariant={"outline-solid"}
							label={_fiatCurrencyOut}
							height={"18px"}
							width={"32px"}
							fontSize={"10px"}
							onClick={() => toggleCurrency(field)}
						/>
					</View>
				</View>
			);
		},
		[quote, exchangeTickerPriceCurrency]
	);

	const [exchangeRateMode, setExchangeRateMode] = useState<"InOut" | "OutIn">(
		"OutIn"
	);

	const toggleExchangeRateMode = () => {
		setExchangeRateMode((state) =>
			(state || "InOut") === "InOut" ? "OutIn" : "InOut"
		);
	};

	const type =
		quote?.entry === "amountIn"
			? RequestTypeEnum.swapExactFiatForCrypto
			: RequestTypeEnum.swapFiatForExactCrypto;

	return (
		<View
			style={[
				styles.card,
				isMobile ? { flex: 1, width: "100%" } : { flex: 1 },
				!isMobile && styles.detailsCard,
			]}
		>
			<SwapModalTitle title={t("swap-modal.details.title")} />

			<View style={[styles.detailsContent]}>
				<SwapModalDetailRow
					label={
						type === RequestTypeEnum.swapExactFiatForCrypto
							? t("swap-modal.details.will-be-sent-exactly")
							: t("swap-modal.details.will-be-sent")
					}
					value={
						amountIn && currencyIn
							? CurrencyUtil.formatCurrency(
									amountIn,
									currencyIn,
									true,
									currencyIn === "BRL" ? 2 : 4,
									currencyIn === "BRL" ? 2 : 4
							  )
							: "-"
					}
					rightAddon={
						<>
							<FiatPriceTickerView
								field={"amountIn"}
								currency={quote?.currencyIn}
								calculatedSwapQuote={quote!}
							/>
						</>
					}
				/>

				<SwapModalDetailRow
					label={
						type === RequestTypeEnum.swapFiatForExactCrypto
							? t("swap-modal.details.will-be-received-exactly")
							: t("swap-modal.details.will-be-received")
					}
					value={
						netAmountOut && currencyOut
							? CurrencyUtil.formatCurrency(
									netAmountOut,
									currencyOut,
									true,
									currencyOut === "BRL" ? 2 : 4,
									currencyOut === "BRL" ? 2 : 4
							  )
							: "-"
					}
					rightAddon={
						<>
							<FiatPriceTickerView
								field={"netAmountOut"}
								currency={quote?.currencyOut}
								calculatedSwapQuote={quote!}
							/>
						</>
					}
				/>

				<View style={[styles.divider]} />

				<SwapModalDetailRow
					label={t("swap-modal.details.rate")}
					value={
						isQuoting ? (
							<>
								<ActivityIndicator
									size={12}
									color={"#000"}
									style={{ marginRight: 8 }}
								/>
							</>
						) : quotes[currencyOut + currencyIn] ? (
							details["exchangeRate" + exchangeRateMode]
						) : (
							"-"
						)
					}
					rightAddon={
						<Button
							size={"table"}
							fillVariant={"ghost"}
							icon={<Icon name={"SwapHorizontal"} fontSize={"16px"} />}
							label={""}
							onClick={() => toggleExchangeRateMode()}
						/>
					}
				/>
				<SwapModalDetailRow
					label={t("swap-modal.details.service-fee", {
						value: percentage ? percentage : "0",
					})}
					value={
						serviceAmountFee && serviceCurrencyFee
							? CurrencyUtil.formatCurrency(
									serviceAmountFee,
									serviceCurrencyFee,
									true,
									serviceCurrencyFee === "BRL" ? 2 : 4,
									serviceCurrencyFee === "BRL" ? 2 : 4
							  )
							: "-"
					}
					rightAddon={
						<>
							<Button
								size={"table"}
								fillVariant={"outline-solid"}
								label={"0%"}
								height={"18px"}
								width={"28px"}
								fontSize={"10px"}
								onClick={() => toggleServiceFee("0")}
							/>
							<Button
								size={"table"}
								fillVariant={"outline-solid"}
								label={"0,5%"}
								height={"18px"}
								width={"28px"}
								fontSize={"10px"}
								onClick={() => toggleServiceFee("0.005")}
							/>
							<Button
								size={"table"}
								fillVariant={"outline-solid"}
								label={"1%"}
								height={"18px"}
								width={"28px"}
								fontSize={"10px"}
								onClick={() => toggleServiceFee("0.01")}
							/>
							<Button
								size={"table"}
								fillVariant={"outline-solid"}
								label={"2%"}
								height={"18px"}
								width={"28px"}
								fontSize={"10px"}
								onClick={() => toggleServiceFee("0.02")}
							/>
							<Button
								size={"table"}
								fillVariant={"outline-solid"}
								label={"3%"}
								height={"18px"}
								width={"28px"}
								fontSize={"10px"}
								onClick={() => toggleServiceFee("0.03")}
							/>
						</>
					}
				/>

				<SwapModalDetailRow
					label={t("swap-modal.details.network-fee")}
					value={
						networkAmountFee && networkCurrencyFee
							? CurrencyUtil.formatCurrency(
									networkAmountFee,
									networkCurrencyFee,
									true,
									0,
									0
							  )
							: "-"
					}
				/>
			</View>
		</View>
	);
}

export default function CalcScreen(props: any) {
	const { t } = useTranslation();

	const { currencyIn, currencyOut, calcDirection, network } =
		useCurrencyParams();

	const [quote, setQuote] = useState<SwapModalQuoteProps>();

	const [isAmountInInvalid, setIsAmountInInvalid] = useState(false);

	const [isEWalletUserModeEnabled, setIsEWalletUserModeEnabled] =
		useState(false);

	const [isNetAmountOutInvalid, setIsNetAmountOutInvalid] = useState(false);

	const [exchangeRateMode, setExchangeRateMode] = useState<"InOut" | "OutIn">(
		"OutIn"
	);

	const [serviceFee, setServiceFee] = useState<ServiceFeeTypes>("0.03");

	const [calculatedSwapQuote, setCalculatedSwapQuote] =
		useState<QuoteSwapDataResponse>();

	const { tenant } = useMultiTenancy();

	const [swapDirection, setSwapDirection] =
		useState<SwapModalSwapDirectionEnum>(
			calcDirection
				? calcDirection === "fromCryptoToFiat"
					? SwapModalSwapDirectionEnum.fromCryptoToFiat
					: SwapModalSwapDirectionEnum.fromFiatToCrypto
				: SwapModalSwapDirectionEnum.fromFiatToCrypto
		);

	const {
		mutateAsync: handleQuoteSwap,
		isLoading: isQuotingSwap,
		variables: quotingSwapData,
	} = useMutation({
		mutationFn: SwapService.quoteSwap,
		onError: () => {},
	});

	const currencyService = new CurrencyService();

	const { data: _CurrenciesDataResponse, isFetching: isFetchingCurrencies } =
		useQuery(["currencies"], {
			queryFn: currencyService.getCurrencies,
			retry: false,
			refetchOnWindowFocus: true,
			staleTime: 60 * 60 * 1000, // 1 hour
		});

	const { data: _MarketsDataResponse, isFetching: isFetchingMarkets } =
		useQuery(["markets"], {
			queryFn: currencyService.getMarkets,
			retry: false,
			refetchOnWindowFocus: true,
			staleTime: 60 * 60 * 1000, // 1 hour
		});

	const { currencyInOptions, currencyOutOptions, networkOptions } =
		useMemo(() => {
			const currencies = _CurrenciesDataResponse?.data?.data || [];

			const markets = _MarketsDataResponse?.data?.data || [];

			const availableCurrencies: TCurrencySymbol[] = currencies.map(
				(currency) => currency.currency
			);

			const currencyFiatOptions: ICurrencyOption[] = markets
				.map((market) => market.quoteCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index && availableCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.BRL ? -1 : 1
				)
				.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					type: currency.type,
					networks: currency.withdraw.networks.map((network) => network.nameId),
				}));

			const currentCurrencyIn = currencyFiatOptions[0]?.value;

			const availableCurrencyOutCurrencies = markets
				.filter((market) => market.quoteCurrency.currency === currentCurrencyIn)
				.map((market) => market.baseCurrency.currency)
				.filter((currency) => availableCurrencies.includes(currency));

			const currencyCryptoOptions: ICurrencyOption[] = markets
				.map((market) => market.baseCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index &&
						availableCurrencyOutCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.USDT ? -1 : 1
				)
				.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					type: currency.type,
					networks: currency.withdraw.networks.map((network) => network.nameId),
				}));

			let networks: INetwork[] = [];

			currencies.forEach((currency) => {
				currency.withdraw.networks.forEach((network) => networks.push(network));
			});

			const networkOptions: INetworkOption[] = networks
				.filter(
					(network, index, array) =>
						array.findIndex((item) => item.nameId === network.nameId) === index
				) //exclude duplicate networks
				.map((network) => ({
					value: network.nameId,
					label:
						network.currencySymbol === TCurrencySymbol.BRL
							? network.currencySymbol
							: network.description,
					icon: network.currencySymbol,
					...(network.currencySymbol === TCurrencySymbol.BRL
						? {
								subLabel:
									network.nameId === "internal-mainnet"
										? "e-wallet"
										: network.nameId,
						  }
						: {}),
					walletConfig: {
						placeholder: network.placeholder,
						validateName: network.nameId.split("-")[0],
						maxLength: 42,
					},
				}));

			return {
				currencyInOptions: currencyFiatOptions,
				currencyOutOptions: currencyCryptoOptions,
				networkOptions,
			};
		}, [_CurrenciesDataResponse, _MarketsDataResponse]);

	const calcFormValidationSchema = Yup.object({
		amountIn: Yup.string().required("").typeError(""),
		currencyIn: Yup.string().required("").typeError(""),
		currencyOut: Yup.string().required("").typeError(""),
		networkIn: Yup.string().required("").typeError(""),
		networkOut: Yup.string().required("").typeError(""),
	});

	const calcForm = useFormik<CalcFormData>({
		initialValues: {
			type: "swapExactAmountIn",
			amountIn: "",
			currencyIn: currencyIn
				? swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? currencyInOptions.find((currency) => currency?.value === currencyIn)
					: currencyOutOptions.find(
							(currency) => currency?.value === currencyIn
					  )
				: currencyInOptions[0],

			currencyOut: currencyOut
				? swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? currencyOutOptions.find(
							(currency) => currency?.value === currencyOut
					  )
					: currencyInOptions.find(
							(currency) => currency?.value === currencyOut
					  )
				: currencyOutOptions[0],
			netAmountOut: "",
			networkIn: currencyIn
				? swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? (networkOptions
							.filter((network) =>
								currencyInOptions[0]?.networks?.includes(network.value)
							)
							.find(
								(network) =>
									network.value ===
									([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
										? "p2p-mainnet"
										: [TenantAppDomainEnum.BANKEI].includes(tenant.name)
										? "internal-mainnet"
										: "pix-mainnet")
							) as any)
					: network
					? networkOptions
							.filter((network) =>
								currencyOutOptions
									.find((currency) => currency?.value === currencyIn)
									?.networks?.includes(network.value)
							)
							.find((networkIn) => networkIn?.value === network)
					: networkOptions.filter((network) =>
							currencyOutOptions
								.find((currency) => currency?.value === currencyIn)
								?.networks?.includes(network.value)
					  )[0]
				: (networkOptions
						.filter((network) =>
							currencyInOptions[0]?.networks?.includes(network.value)
						)
						.find(
							(network) =>
								network.value ===
								([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
									? "p2p-mainnet"
									: [TenantAppDomainEnum.BANKEI].includes(tenant.name)
									? "internal-mainnet"
									: "pix-mainnet")
						) as any),
			networkOut: currencyOut
				? swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? network
						? networkOptions
								.filter((network) =>
									currencyOutOptions
										.find((currency) => currency?.value === currencyOut)
										?.networks?.includes(network.value)
								)
								.find((networkOut) => networkOut.value === network)
						: networkOptions.filter((network) =>
								currencyOutOptions
									.find((currency) => currency?.value === currencyOut)
									?.networks?.includes(network.value)
						  )[0]
					: (networkOptions
							.filter((network) =>
								currencyInOptions[0]?.networks?.includes(network.value)
							)
							.find(
								(network) =>
									network.value ===
									([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
										? "p2p-mainnet"
										: [TenantAppDomainEnum.BANKEI].includes(tenant.name)
										? "internal-mainnet"
										: "pix-mainnet")
							) as any)
				: networkOptions.filter((network) =>
						currencyOutOptions[0]?.networks?.includes(network.value)
				  )[0],
		},
		validationSchema: calcFormValidationSchema,
		validateOnChange: false,
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: () => {},
	});

	const availableNetworksOptions =
		swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
			? networkOptions?.filter((network) =>
					calcForm.values?.currencyOut?.networks?.includes(network.value)
			  )
			: networkOptions?.filter((network) =>
					calcForm.values?.currencyIn?.networks?.includes(network.value)
			  );

	const toggleServiceFee = async (fee: ServiceFeeTypes) => {
		setServiceFee(fee);

		const field = (
			quote
				? quote.entry === "netAmountOut"
				: calculatedSwapQuote?.entry === "netAmountOut"
		)
			? "netAmountOut"
			: "amountIn";

		calculatePairAmount({ field: field });
	};

	const handleEWalletBalanceMode = async () => {
		setIsEWalletUserModeEnabled(!isEWalletUserModeEnabled);

		calculatePairAmount({
			field: calculatedSwapQuote?.entry ?? "amountIn",
		});
	};

	const handleChangeSwapDirection = useCallback(async () => {
		if (isQuotingSwap) return;

		const amountIn = CurrencyUtil.getNumberByValue(
			calculatedSwapQuote?.amountIn ?? calcForm.values.amountIn
		);
		const netAmountOut = CurrencyUtil.getNumberByValue(
			calculatedSwapQuote?.netAmountOut ?? calcForm.values.netAmountOut
		);

		const entry: QuoteSwapDataResponse["entry"] =
			quote?.entry ?? calculatedSwapQuote?.entry ?? "amountIn";

		await calcForm.setFieldValue("amountIn", netAmountOut);
		await calcForm.setFieldValue("netAmountOut", amountIn);

		await calcForm.setFieldValue("currencyIn", calcForm?.values?.currencyOut);
		await calcForm.setFieldValue("currencyOut", calcForm?.values?.currencyIn);

		await calcForm.setFieldValue("networkIn", calcForm?.values?.networkOut);
		await calcForm.setFieldValue("networkOut", calcForm?.values?.networkIn);

		toggleExchangeRateMode();

		calculatePairAmount({
			currencyIn: calcForm?.values?.currencyOut?.value,
			currencyOut: calcForm?.values?.currencyIn?.value,
			networkIn: calcForm?.values?.networkOut?.value,
			networkOut: calcForm?.values?.networkIn?.value,
			field: entry === "amountIn" ? "netAmountOut" : "amountIn",
			amount: entry === "amountIn" ? Number(amountIn) : Number(netAmountOut),
		});

		// setSwapDirection(
		// 	swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
		// 		? SwapModalSwapDirectionEnum.fromCryptoToFiat
		// 		: SwapModalSwapDirectionEnum.fromFiatToCrypto
		// );

		// console.log("currencyIn:", calcForm?.values?.currencyIn?.value);
		// console.log("networkIn:", calcForm?.values?.networkIn?.value);

		// console.log("currencyOut:", calcForm?.values?.currencyOut?.value);
		// console.log("networkOut:", calcForm?.values?.networkOut?.value);

		// console.log(
		// 	"entry",
		// 	quote?.entry ?? calculatedSwapQuote?.entry ?? "amountIn"
		// );
	}, [
		calcForm.values.currencyIn,
		calcForm.values.currencyOut,
		calcForm.values,
	]);

	const handleChangeCurrency = async (
		option: ICurrencyOption,
		field: "currencyIn" | "currencyOut" = "currencyOut"
	) => {
		const _networkField = field === "currencyIn" ? "networkIn" : "networkOut";

		let _networkNameId;

		_networkNameId = (
			field === "currencyIn"
				? swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? currencyInOptions
					: currencyOutOptions
				: currencyOutOptions
		).filter((currency) => currency.value === option.value)[0]?.networks[0];

		const _networkOptions =
			networkOptions.filter(
				(network: any) => network.value === _networkNameId
			) || [];

		await calcForm.setFieldValue(_networkField, _networkOptions[0]);

		calculatePairAmount({
			field: (
				quote
					? quote.entry === "netAmountOut"
					: calculatedSwapQuote?.entry === "netAmountOut"
			)
				? "netAmountOut"
				: "amountIn",
			networkIn:
				_networkField === "networkIn"
					? _networkOptions[0]?.value
					: calcForm.values?.networkIn?.value,
			networkOut:
				_networkField === "networkOut"
					? _networkOptions[0]?.value
					: calcForm.values?.networkOut?.value,
			[field]: option.value,
		});
	};

	const toggleExchangeRateMode = () => {
		setExchangeRateMode((state) =>
			(state || "InOut") === "InOut" ? "OutIn" : "InOut"
		);
	};

	const calculatePairAmount = ({
		field,
		amount,
		currencyIn,
		currencyOut,
		networkIn,
		networkOut,
		direction,
	}: SwapModalCalculatePairAmountParams) => {
		const entry =
			(field === "network"
				? calculatedSwapQuote?.entry ?? "amountIn"
				: field) === "netAmountOut"
				? "netAmountOut"
				: "amountIn";

		setQuote({
			type:
				field === "netAmountOut"
					? "swapExactNetAmountOut"
					: "swapExactAmountIn",
			entry: entry,
			[entry]:
				amount ??
				CurrencyUtil.getNumberByValue(
					entry === "netAmountOut"
						? calcForm.values.netAmountOut ?? "0.0"
						: calcForm.values.amountIn ?? "0.0"
				),
			currencyIn: currencyIn || calcForm?.values?.currencyIn?.value,
			currencyOut: currencyOut || calcForm?.values?.currencyOut?.value,
			networkIn: networkIn || calcForm?.values?.networkIn?.value,
			networkOut: networkOut ?? calcForm?.values?.networkOut?.value,
			requestedAt: new Date(),
		});
	};

	const debouncedQuote = useDebouncedValue(quote, 300);

	useEffect(() => {
		if (debouncedQuote) {
			handleQuoteSwap({
				amountIn: debouncedQuote.amountIn,
				netAmountOut: debouncedQuote.netAmountOut,
				currencyIn: debouncedQuote.currencyIn,
				currencyOut: debouncedQuote.currencyOut,
				networkIn: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: debouncedQuote.networkIn ?? null,
				networkOut: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: debouncedQuote.networkOut ?? null,
				user: "",
				serviceFee: serviceFee,
			})
				.then((data) => {
					if (
						data?.amountInMin >
							(data?.entry && data?.entry === "amountIn"
								? debouncedQuote.amountIn || 0
								: CurrencyUtil.getNumberByValue(data?.amountIn)) ||
						data?.amountInMax <
							(data?.entry && data?.entry === "amountIn"
								? debouncedQuote?.amountIn || 0
								: CurrencyUtil.getNumberByValue(data?.amountIn))
					) {
						setIsAmountInInvalid(true);
					} else {
						setIsAmountInInvalid(false);
					}

					if (
						data?.netAmountOutMin >
							(data?.entry && data?.entry === "netAmountOut"
								? debouncedQuote?.netAmountOut || 0
								: CurrencyUtil.getNumberByValue(data?.netAmountOut)) ||
						data?.netAmountOutMax <
							(data?.entry && data?.entry === "netAmountOut"
								? debouncedQuote?.netAmountOut || 0
								: CurrencyUtil.getNumberByValue(data?.netAmountOut))
					) {
						setIsNetAmountOutInvalid(true);
					} else {
						setIsNetAmountOutInvalid(false);
					}

					setCalculatedSwapQuote((prev) => {
						if (!prev?.requestedAt || !debouncedQuote?.requestedAt) {
							return { ...data, requestedAt: debouncedQuote?.requestedAt };
						}

						if (moment(debouncedQuote.requestedAt).isAfter(prev.requestedAt)) {
							return { ...data, requestedAt: debouncedQuote?.requestedAt };
						}

						return prev; //set the previous value if the calculated value is outdated compared to previous value;
					});
				})
				.finally(() => {});
		}
	}, [debouncedQuote]);

	useEffect(() => {
		if (calculatedSwapQuote) {
			if (calculatedSwapQuote.entry === "amountIn") {
				calcForm.setFieldValue(
					"netAmountOut",
					calculatedSwapQuote.netAmountOut
				);
			} else {
				calcForm.setFieldValue("amountIn", calculatedSwapQuote.amountIn, true);
			}
		}
	}, [calculatedSwapQuote]);

	const { user: authUser } = useAuth();

	return (
		<>
			<View
				style={[
					!authUser
						? GeneralCSS.notLoggedContainer
						: { display: "flex", flex: 1, minHeight: 580 },
				]}
			>
				{!authUser && <Header props={props} />}

				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<View
						style={[
							isMobile
								? {
										width: "100%",
										display: "flex",
										flexDirection: "column",
								  }
								: {
										width: "100%",
										maxWidth: "800px",
										minWidth: "180px",
										textAlign: "center",
										display: "flex",
										flexDirection: "column",
										backgroundColor: "#F1F1F1",
										boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
								  },
						]}
					>
						<View
							variant="primary"
							style={[
								isMobile
									? {
											display: "none",
									  }
									: {
											width: "100%",
											height: 55,
											color: "#FFF",
											flexFlow: "row",
											padding: "0 16px",
											display: "flex",
											alignItems: "center",
											justifyContent: "space-between",
											position: "relative",
											fontSize: "16px",
											borderTopLeftRadius: 10,
											borderTopRightRadius: 10,
									  },
							]}
						>
							<View
								style={[
									isMobile
										? {
												display: "none",
										  }
										: {
												display: "flex",
												flex: 1,
												flexDirection: "row",
												alignItems: "center",
										  },
								]}
							>
								<View style={{ marginRight: 8 }}>
									<Icon
										name={"SwapHorizontal"}
										fontSize={"24px"}
										color="#fff"
									/>
								</View>
								<Text
									style={[
										{
											textAlign: "left",
											float: "left",
											whiteSpace: "nowrap",
											color: "#FFF",
											fontSize: "16px",
											fontWeight: "700",
										},
									]}
								>
									{t("calc-modal.title")}
								</Text>
							</View>
						</View>
						<View
							style={[
								isMobile
									? {
											width: "100%",
											padding: "16px",
											gap: "16px",
											textAlign: "justify",
											display: "flex",
											flexDirection: "column",
									  }
									: {
											width: "100%",
											padding: "16px",
											paddingRight: "12px",
											marginRight: "4px",
											marginTop: "4px",
											marginBottom: "4px",
											textAlign: "justify",
											float: "left",
											gap: "16px",
									  },
							]}
						>
							<View
								style={[
									isMobile
										? {
												display: "flex",
												flexDirection: "column",
												gap: 16,
												flexWrap: "wrap",
												paddingTop: 80,
												width: "100%",
										  }
										: {
												display: "flex",
												flexDirection: "row",
												gap: 16,
												flexWrap: "wrap",
										  },
								]}
							>
								<View
									style={[
										styles.card,
										isMobile
											? { flex: 1, width: "100%" }
											: { flex: 1, height: "100%" },
									]}
								>
									<View
										style={{
											overFlow: "hidden",
										}}
									>
										<SwapModalTitle
											title={t("swap-modal.origin.title")}
											icon={"BoxArrowInDown"}
											badgeText={t("swap.table.rows.exactly")}
											showBadge={
												quote
													? quote.entry === "amountIn"
													: calculatedSwapQuote?.entry === "amountIn"
											}
											rightAddon={
												!isMobile ? (
													<View
														style={{
															justifyContent: "space-between",
															flexDirection: "row",
															display: "flex",
														}}
													>
														{/* <Checkbox
															size={"small"}
															checked={isEWalletUserModeEnabled}
															onChange={handleEWalletBalanceMode}
															label={t(`swap-modal.e-wallet.user-mode-enabled`)}
														/> */}
														{![TenantAppDomainEnum.EZZEPAY].includes(
															tenant.name
														) ? (
															<Checkbox
																size={"small"}
																checked={isEWalletUserModeEnabled}
																onChange={handleEWalletBalanceMode}
																label={t(
																	`swap-modal.e-wallet.user-mode-${
																		isEWalletUserModeEnabled
																			? "enabled"
																			: "disabled"
																	}`
																)}
															/>
														) : (
															<View>
																<Text
																	style={{
																		fontSize: 12,
																		color: isEWalletUserModeEnabled
																			? "green"
																			: "red",
																	}}
																>
																	{t(
																		`swap-modal.e-wallet.user-mode-${
																			isEWalletUserModeEnabled
																				? "enabled"
																				: "disabled"
																		}`
																	)}
																</Text>
															</View>
														)}
													</View>
												) : undefined
											}
										/>
									</View>

									<View>
										<Input
											autoComplete={"off" /* "new-amountIn" */}
											paddingRight={"176px"}
											size={"large"}
											coin={
												calcForm.values?.currencyIn?.value ===
												TCurrencySymbol.BRL
													? "BRL"
													: "BTC"
											}
											value={calcForm.values?.amountIn}
											onChange={async (value: any) => {
												// if (isReadOnly) return;
												await calcForm.setFieldValue("amountIn", value);
											}}
											onKeyUp={async () => {
												await calcForm.setFieldTouched("amountIn");

												calculatePairAmount({ field: "amountIn" });
											}}
											decimalConfig={{
												decimalScale:
													calcForm.values.currencyIn?.value ===
													TCurrencySymbol.BRL
														? 2
														: 6,
												decimalsLimit:
													calcForm.values.currencyIn?.value ===
													TCurrencySymbol.BRL
														? 2
														: 6,
											}}
											variant={undefined}
											rightAddon={{
												buttons: [
													<Select
														size={"medium"}
														inputMinWidth={"112px"}
														selectPadding={"0px"}
														placeholder={""}
														isSearchable={false}
														paddingRight={"0px"}
														iconSize={24}
														iconSet={"currency"}
														disabled={false}
														readonly={
															swapDirection ===
															SwapModalSwapDirectionEnum.fromFiatToCrypto
																? currencyInOptions.length <= 1
																	? true
																	: false
																: currencyOutOptions.length <= 1
																? true
																: false
														}
														options={
															swapDirection ===
															SwapModalSwapDirectionEnum.fromFiatToCrypto
																? currencyInOptions
																: currencyOutOptions
														}
														value={calcForm.values.currencyIn}
														onChange={async (option) => {
															await calcForm.setFieldValue(
																"currencyIn",
																option
															);
															await handleChangeCurrency(option, "currencyIn");
														}}
													/>,
												],
											}}
											errorMessage={
												calculatedSwapQuote
													? calcForm.touched.amountIn &&
													  (calcForm.errors.amountIn || isAmountInInvalid)
														? t(`swap-modal.fields-errors.amount-min-max`, {
																min: `${CurrencyUtil.formatCurrency(
																	calculatedSwapQuote?.amountInMin,
																	calculatedSwapQuote?.currencyIn ||
																		calcForm.values.currencyIn?.value
																)}`,
																max: `${CurrencyUtil.formatCurrency(
																	calculatedSwapQuote?.amountInMax,
																	calculatedSwapQuote?.currencyIn ||
																		calcForm.values.currencyIn?.value
																)}`,
														  })
														: ""
													: ""
											}
										/>
									</View>

									<SwapModalTitle
										title={t("swap-modal.destination.title")}
										icon={"BoxArrowUp"}
										badgeText={t("swap.table.rows.exactly")}
										showBadge={
											quote
												? quote.entry === "netAmountOut"
												: calculatedSwapQuote?.entry === "netAmountOut"
										}
										rightAddon={
											<>
												{/* {!currencyIn && !currencyOut && (
													<SwapChangeTypeButton
														onPress={handleChangeSwapDirection}
														disabled={isQuotingSwap}
													/>
												)} */}
												<SwapChangeTypeButton
													onPress={handleChangeSwapDirection}
													disabled={isQuotingSwap}
												/>
											</>
										}
									/>
									<Input
										autoComplete={"off" /* "new-netAmountOut" */}
										paddingRight={"176px"}
										size={"large"}
										coin={
											calcForm?.values?.currencyIn?.value !==
											TCurrencySymbol.BRL
												? "BRL"
												: "BTC"
										}
										onChange={async (value: any) => {
											await calcForm.setFieldValue("netAmountOut", value);
										}}
										value={calcForm.values.netAmountOut}
										onKeyUp={async () => {
											await calcForm.setFieldTouched("netAmountOut");
											setIsNetAmountOutInvalid(false);
											calculatePairAmount({ field: "netAmountOut" });
										}}
										decimalConfig={{
											decimalScale:
												calcForm.values?.currencyOut?.value ===
												TCurrencySymbol.BRL
													? 2
													: 6,
											decimalsLimit:
												calcForm.values?.currencyOut?.value ===
												TCurrencySymbol.BRL
													? 2
													: 6,
										}}
										rightAddon={{
											buttons: [
												<Select
													size={"medium"}
													inputMinWidth={"112px"}
													selectPadding={"0px"}
													placeholder={""}
													iconSize={24}
													iconSet={"currency"}
													options={
														swapDirection ===
														SwapModalSwapDirectionEnum.fromFiatToCrypto
															? currencyOutOptions
															: currencyInOptions
													}
													readonly={
														swapDirection ===
														SwapModalSwapDirectionEnum.fromFiatToCrypto
															? currencyOutOptions.length <= 1
																? true
																: false
															: currencyInOptions.length <= 1
															? true
															: false
													}
													value={calcForm.values.currencyOut}
													onChange={async (option) => {
														await calcForm.setFieldValue("currencyOut", option);
														await handleChangeCurrency(option, "currencyOut");
													}}
												/>,
											],
										}}
										errorMessage={
											calculatedSwapQuote
												? calcForm.touched.netAmountOut &&
												  (calcForm.errors.netAmountOut ||
														isNetAmountOutInvalid)
													? t(`swap-modal.fields-errors.amount-min-max`, {
															min: `${CurrencyUtil.formatCurrency(
																calculatedSwapQuote?.netAmountOutMin,
																calculatedSwapQuote?.currencyOut ||
																	calcForm.values.currencyOut?.value
															)}`,
															max: `${CurrencyUtil.formatCurrency(
																calculatedSwapQuote?.netAmountOutMax,
																calculatedSwapQuote?.currencyOut ||
																	calcForm.values.currencyOut?.value
															)}`,
													  })
													: ""
												: ""
										}
									/>

									{isEWalletUserModeEnabled === false &&
										calcForm.values.currencyIn?.type ===
											CurrencyTypeEnum.CRYPTO && (
											<Select
												size={"large"}
												label={t("swap-modal.destination.network")}
												labelStyle="default"
												inputWidth={"100%"}
												placeholder={""}
												iconSize={28}
												iconSet={"currency"}
												readonly={
													availableNetworksOptions
														.map((x) => ({
															...x,
															subLabel:
																x.value === "internal-mainnet"
																	? "e-wallet"
																	: x.value,
														}))
														.filter((network) => {
															return calcForm.values.currencyIn.networks?.includes(
																network.value
															);
														}).length <= 1
												}
												isSearchable={false}
												options={availableNetworksOptions
													.map((x) => ({
														...x,
														subLabel:
															x.value === "internal-mainnet"
																? "e-wallet"
																: x.value,
													}))
													.filter((network) => {
														return calcForm.values.currencyIn.networks?.includes(
															network.value
														);
													})}
												value={calcForm.values.networkIn}
												onChange={async (option) => {
													await calcForm.setFieldValue("networkIn", option);
													calculatePairAmount({
														field: "network",
														networkOut: option.value,
														addressOut: "",
													});
												}}
												paddingRight={"10px"}
											/>
										)}

									{isEWalletUserModeEnabled === false &&
										calcForm.values.currencyOut?.type ===
											CurrencyTypeEnum.CRYPTO && (
											<Select
												size={"large"}
												label={t("swap-modal.destination.network")}
												labelStyle="default"
												inputWidth={"100%"}
												placeholder={""}
												iconSize={28}
												iconSet={"currency"}
												readonly={
													availableNetworksOptions
														.map((x) => ({
															...x,
															subLabel:
																x.value === "internal-mainnet"
																	? "e-wallet"
																	: x.value,
														}))
														.filter((network) => {
															return calcForm.values.currencyOut.networks?.includes(
																network.value
															);
														}).length <= 1
												}
												isSearchable={false}
												options={availableNetworksOptions
													.map((x) => ({
														...x,
														subLabel:
															x.value === "internal-mainnet"
																? "e-wallet"
																: x.value,
													}))
													.filter((network) => {
														return calcForm.values.currencyOut.networks?.includes(
															network.value
														);
													})}
												value={calcForm.values.networkOut}
												onChange={async (option) => {
													await calcForm.setFieldValue("networkOut", option);
													calculatePairAmount({
														field: "network",
														networkOut: option.value,
														addressOut: "",
													});
												}}
												paddingRight={"10px"}
											/>
										)}
								</View>

								<SwapDetailsCard
									quoteData={calculatedSwapQuote}
									isQuoting={isQuotingSwap}
									serviceFee={serviceFee}
									toggleServiceFee={toggleServiceFee}
								/>
							</View>
						</View>
					</View>
				</View>
			</View>
		</>
	);
}
