import { DefaultFilter } from "../../models/DefaultFilter";
import API, { corePrefix } from "../api";
import Util from "../../utils";
import { UserModel } from "../../models/UserModel";
import { IDocumentProof } from "../../../pages/approved-users/UserApprovalModal/UserDocsColumn";
import { RequestModel } from "../../models/RequestModel";
import { UserTypeEnum } from "../../enums/UserType.enum";
import { isYesOrNoEnum } from "src/types/types";
export type UserFileType =
	| "selfieDocApproval"
	| "proofAddress"
	| "proofFrontDoc"
	| "proofBackDoc"
	| "proofCompany"
	| "proofAddressCompany";

export enum EApprovedUsers {
	ALL = "all",
	PENDING = "pending",
	APPROVED = "approved",
	REPROVED = "reproved",
	PENDING_DOCUMENT = "pending_document",
}

export enum E_USER_TYPE {
	PHYSICAL_PERSON = 1,
	LEGAL_PERSON = 2,
}

interface UpdateDocumentProofValues {
	user: UserModel;
	document: IDocumentProof;
	reason?: string;
	action: "approve" | "reprove";
}

interface GetUsersResponse {
	status: "ok" | "error";
	data: {
		count: number;
		limit: number;
		page: number;
		rows: UserModel[];
	};
}

interface GetUserRequestsResponse {
	status: "ok" | "error";
	data: {
		data: {
			count: number;
			limit: number;
			page: number;
			rows: RequestModel[];
		};
	};
}

export interface CreateUserData {
	isTrusted: isYesOrNoEnum;
	userType: UserTypeEnum;
	companyName?: string;
	companyLegalName?: string;
	companyId?: string;
	companyBirthdate?: string;
	email?: string;
	name: string;
	personId?: string | null;
	personLegalName?: string;
	birthdate: string | null;
	phone?: string;
	phoneCountryCode?: number;
	lang?: string;
	country: number;
	pinType?: string;
	commissionInReferral0SwapFiatToCryptoPercentageFee: string;
	commissionInReferral0SwapCryptoToFiatPercentageFee: string;
	isAutoFiatExternalWithdrawWalletAddressEnabled: isYesOrNoEnum;
	autoFiatExternalWithdrawWalletNetwork: string;
	autoFiatExternalWithdrawWalletAddress: string;
}

export interface UpdateUserData {
	isTrusted: isYesOrNoEnum;
	companyName?: string;
	companyLegalName?: string;
	companyBirthdate?: string;
	email?: string;
	name: string;
	personLegalName?: string;
	birthdate?: string | null;
	phone?: string;
	phoneCountryCode?: number;
	personId?: string | null;
	commissionInReferral0SwapFiatToCryptoPercentageFee: string;
	commissionInReferral0SwapCryptoToFiatPercentageFee: string;
	isAutoFiatExternalWithdrawWalletAddressEnabled: isYesOrNoEnum;
	autoFiatExternalWithdrawWalletNetwork: string;
	autoFiatExternalWithdrawWalletAddress: string;
}

export default class UsersService {
	private static instance: UsersService;

	constructor() {}

	public static getInstance(): UsersService {
		if (!UsersService.instance) {
			UsersService.instance = new UsersService();
		}
		return UsersService.instance;
	}

	async getUsers(filter?: DefaultFilter): Promise<GetUsersResponse> {
		for (const key of Object.getOwnPropertyNames(filter?.search)) {
			if (filter?.search[key] && filter?.search[key]["equal"] === "")
				delete filter?.search[key];
		}

		// @reference: filter on https://ezzepay-backoffice.local.hubchainbank.com:19006/users/accounts
		// console.log(`::getUsers::`, filter);

		const response = await API.get(
			`${corePrefix}/reports/users?page=${filter?.page || 1}&limit=${
				filter?.limit || 10
			}&search=${Util.defineSearch(filter)}`
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getAllUsers(filter?: DefaultFilter): Promise<GetUsersResponse> {
		const response = await API.get(`${corePrefix}/reports/users`);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getUsersApprovedManagement(filter?: DefaultFilter) {
		let status: EApprovedUsers = filter?.search.status;
		let userType: E_USER_TYPE = filter?.search.userType;

		for (const key of Object.getOwnPropertyNames(filter?.search)) {
			if (filter?.search[key] && filter?.search[key]["equal"] === "")
				delete filter?.search[key];
		}

		const orderBy = JSON.stringify([
			{ createdAt: status === EApprovedUsers.PENDING ? "ASC" : "DESC" },
			{ name: "ASC" },
		]);

		const response = await API.get(
			`${corePrefix}/reports/users/approvement?page=${
				filter?.page || 1
			}&limit=${
				filter?.limit || 10
			}&status=${status}&search=${Util.defineSearch(filter)}&orderBy=${orderBy}`
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getUsersStatistics(filter?: DefaultFilter) {
		const response = await API.get(
			`${corePrefix}/admin/requests/statistics/approvedUser`
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getFile(id: string) {
		if (!Util.isNullOrEmpty(id)) {
			const response = await API.get(
				`${corePrefix}/admin/users/files?id=${id}`
			);
			const { data } = response;
			if (data) {
				return data;
			} else {
				throw new Error(response.data.reason || response.data.data);
			}
		}
	}

	async getProofsFiles(userId: string) {
		return await API.get(`${corePrefix}/admin/users/files?id=${userId}`);
	}

	async reproveDocumentProof(
		user: UserModel,
		document: IDocumentProof,
		reason: string
	) {
		const body = {
			user: user.id,
			approved: "N",
			document: document.id,
			reason: reason,
		};
		const response = await API.put(
			`${corePrefix}/admin/users/documentProof`,
			body
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async approveDocumentProof(user: UserModel, document: IDocumentProof) {
		const body = {
			user: user.id,
			approved: "Y",
			document: document.id,
		};

		const response = await API.put(
			`${corePrefix}/admin/users/documentProof`,
			body
		);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async updateDocumentProof({
		user,
		document,
		reason,
		action,
		...rest
	}: UpdateDocumentProofValues) {
		const body = {
			user: user.id,
			approved: action === "approve" ? "Y" : "N",
			document: document.id,
			reason: reason,
		};

		if (action === "approve") {
			delete body.reason;
		}

		return await API.put(`${corePrefix}/admin/users/documentProof`, body);
	}

	async uploadFile(document: UserFileType, formData: FormData) {
		const response = await API.post(
			`${corePrefix}/admin/users/upload/${document}`,
			formData
		);
		const { data } = response;
		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async blockUser(userId: UserModel["id"], isLocked = "Y") {
		const response = await API.post(`${corePrefix}/admin/users/lock`, {
			user: userId,
			isLocked: isLocked,
		});
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getUserApiKey(id: string) {
		const response = await API.get(`${corePrefix}/admin/users/apis?id=${id}`);
		const { data } = response;

		if (data) {
			return data;
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async getUserRequests(
		userId: string,
		filter: DefaultFilter
	): Promise<GetUserRequestsResponse> {
		filter.search = {
			...filter.search,
			user: { id: userId },
		};

		return API.get(
			`${corePrefix}/reports/requests?page=${filter.page}&limit=${
				filter.limit
			}&search=${Util.defineSearch(filter)}&orderBy=[{"createdAt":"${
				filter.orderBy?.createdAt || "DESC"
			}"}]`
		);
	}

	async create(user: CreateUserData) {
		let route = `${corePrefix}/admin/users`;

		if (user?.userType === UserTypeEnum.LEGAL_PERSON) {
			route += "/company";
		}

		const response = await API.post(route, { ...user });
		const responseData = response.data.data;

		if (response.data.status == "ok") {
			return {
				message: responseData.message,
			};
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}

	async update(userId: string, user: UpdateUserData) {
		const response = await API.put(`${corePrefix}/admin/user/${userId}`, {
			...user,
		});
		const responseData = response.data.data;

		if (response.data.status == "ok") {
			return {
				message: responseData.message,
			};
		} else {
			throw new Error(response.data.reason || response.data.data);
		}
	}
}
